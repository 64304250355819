import React from 'react';
import { hydrate, render } from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ScrollToTop from './Config/ScrollToTop';

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate(<BrowserRouter><ScrollToTop/><App /></BrowserRouter>, rootElement);
} else {
    render(<BrowserRouter><ScrollToTop/><App /></BrowserRouter>, rootElement);
}