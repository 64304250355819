
export const LegalNoticeContent = (
<div className="notice">
<h2>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h2>
<ol>
  <li>ОБЩИЕ ПОЛОЖЕНИЯ
    <ol>
      <li>Настоящее Пользовательское соглашение (далее – Соглашение) относится к сайту  «Нижегородская Холодильная Лига», расположенному по адресу www.nizhhol.ru, и ко всем соответствующим сайтам, связанным с сайтом www.nizhhol.ru.
      </li>
      <li>Сайт «Нижегородская Холодильная Лига»  (далее – Сайт) является собственностью ООО «Нижегородская Холодильная Лига» (далее  ООО «НХЛ») ОГРН: 1225200029306</li>
      <li>Настоящее Соглашение регулирует отношения между Администрацией сайта «Нижегородская Холодильная Лига» (далее – Администрация сайта) и Пользователем данного Сайта.</li>
      <li>Администрация сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.</li>
      <li>Продолжение использования Сайта Пользователем означает принятие Соглашения и изменений, внесенных в настоящее Соглашение.</li>
      <li>Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.</li>
    </ol>
  </li>
  <li>ОПРЕДЕЛЕНИЯ ТЕРМИНОВ
    <ol>
      <li>Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:
        <ol>
          <li>«Нижегородская Холодильная Лига» – сайт компании ООО «НХЛ» расположенный на доменном имени www.nizhhol.ru, осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов.</li>
          <li>Администрация сайта – уполномоченные сотрудники на управления Сайтом, действующие от имени ООО «НХЛ».</li>
          <li>Пользователь сайта (далее – Пользователь) – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.</li>
          <li>Содержание сайта (далее – Содержание) - охраняемые результаты интеллектуальной деятельности, включая тексты литературных произведений, их названия, предисловия, аннотации, статьи, иллюстрации, обложки, музыкальные произведения с текстом или без текста, графические, текстовые, фотографические, производные, составные и иные произведения, пользовательские интерфейсы, визуальные интерфейсы, названия товарных знаков, логотипы, программы для ЭВМ, базы данных, а также дизайн, структура, выбор, координация, внешний вид, общий стиль и расположение данного Содержания, входящего в состав Сайта и другие объекты интеллектуальной собственности все вместе и/или по отдельности, содержащиеся на сайте.</li>
        </ol>
      </li>
    </ol>
  </li>
  <li>ПРЕДМЕТ СОГЛАШЕНИЯ
<ol>
  <li>Предметом настоящего Соглашения является предоставление Пользователю доступа к содержащимся на Сайте Товарам и оказываемым услугам.
    <ol>
      <li>Сайт предоставляет Пользователю следующие виды услуг (сервисов):
        <ol>
          <li>доступ к электронному контенту без права приобретения (скачивания, копирования), с правом просмотра контента;</li>
          <li>доступ к средствам поиска и навигации Сайта;</li>
          <li>доступ к информации о Товаре и к информации о приобретении Товара на платной основе;</li>
          <li>иные виды услуг (сервисов), реализуемые на страницах Сайта.</li>
        </ol>
      </li>
      <li>Под действие настоящего Соглашения подпадают все существующие (реально функционирующие) на данный момент услуги (сервисы) Сайта, а также любые их последующие модификации и появляющиеся в дальнейшем дополнительные услуги (сервисы) Сайта.</li>
    </ol>
  </li>
  <li>Доступ к Сайта предоставляется на бесплатной основе.</li>
  <li>Настоящее Соглашение является публичной офертой. Получая доступ к Сайту Пользователь считается присоединившимся к настоящему Соглашению.</li>
  <li>Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации</li>
</ol>
 
  </li>
  <li>ПРАВА И ОБЯЗАННОСТИ СТОРОН
    <ol>
      <li>Администрация сайта вправе:
        <ol>
          <li>Изменять правила пользования Сайтом, а также изменять содержание данного Сайта. Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте.</li>
          <li>Ограничить доступ к Сайту в случае нарушения Пользователем условий настоящего Соглашения.</li>
        </ol>
      </li>
      <li>Пользователь вправе:
        <ol>
          <li>Получить доступ к использованию Сайта</li>
          <li>Пользоваться всеми имеющимися на Сайте услугами, а также приобретать любые Товары, предлагаемые на Сайте.</li>
          <li>Задавать любые вопросы, относящиеся к услугам Интернет-магазина по реквизитам, которые находятся в разделе Сайта «О нас».</li>
          <li>Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не запрещенных законодательством Российской Федерации.</li>
        </ol>
      </li>
      <li>Пользователь Сайта обязуется:
        <ol>
          <li>Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет непосредственное отношение к предоставляемым услугам данного Сайта.</li>
          <li>Соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании Сайта.</li>
          <li>Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта.</li>
          <li>Не распространять с использованием Сайта любую конфиденциальную и охраняемую законодательством Российской Федерации информацию о физических либо юридических лицах.</li>
          <li>Избегать любых действий, в результате которых может быть нарушена конфиденциальность охраняемой законодательством Российской Федерации информации.</li>
          <li>Не использовать Сайт для распространения информации рекламного характера, иначе как с согласия Администрации сайта.</li>
          <li>Не использовать сервисы Сайта с целью:
            <ol>
              <li>загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому, религиозному, социальному признакам; содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц, организаций, органов власти.</li>
              <li>побуждения к совершению противоправных действий, а также содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации.</li>
              <li>нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме.</li>
              <li>ущемления прав меньшинств.</li>
              <li>представления себя за другого человека или представителя организации и (или) сообщества без достаточных на то прав, в том числе за сотрудников данного Интернет-магазина.</li>
              <li>введения в заблуждение относительно свойств и характеристик какого-либо Товара из каталога Интернет-магазина, размещенного на Сайте.</li>
              <li>некорректного сравнения Товара, а также формирования негативного отношения к лицам, (не) пользующимся определенными Товарами, или осуждения таких лиц.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>Пользователю запрещается:
        <ol>
          <li>Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания Сайта;</li>
          <li>Нарушать надлежащее функционирование Сайта;</li>
          <li>Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой информации, документов или материалов любыми средствами, которые специально не представлены сервисами данного Сайта;</li>
          <li>Несанкционированный доступ к функциям Сайта, любым другим системам или сетям, относящимся к данному Сайту, а также к любым услугам, предлагаемым на Сайте;</li>
          <li>Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к Сайту.</li>
          <li>Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом другом Пользователе Сайта.</li>
          <li>Использовать Сайт и его Содержание в любых целях, запрещенных законодательством Российской Федерации, а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права интернет-магазина или других лиц.</li>
        </ol>
      </li>
    </ol>
  </li>
  <li>ИСПОЛЬЗОВАНИЕ САЙТА
    <ol>
      <li>Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется Администрацией сайта.</li>
      <li>Содержание Сайта не может быть скопировано, опубликовано, воспроизведено, передано или распространено любым способом, а также размещено в глобальной сети «Интернет» без предварительного письменного согласия Администрации сайта.</li>
      <li>Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также другими правами, связанными с интеллектуальной собственностью, и законодательством о недобросовестной конкуренции.</li>
      <li>Приобретение Товара, предлагаемого на Сайте, может потребовать создания учётной записи Пользователя.</li>
      <li>Пользователь несет персональную ответственность за сохранение конфиденциальности информации учётной записи, включая пароль, а также за всю без исключения деятельность, которая ведётся от имени Пользователя учётной записи.</li>
      <li>Пользователь должен незамедлительно уведомить Администрацию сайта о несанкционированном использовании его учётной записи или пароля или любом другом нарушении системы безопасности.</li>
      <li>Администрация сайта обладает правом в одностороннем порядке аннулировать учетную запись Пользователя, если она не использовалась более 12 календарных месяцев подряд без уведомления Пользователя.</li>
      <li>Настоящее Соглашение распространяет свое действия на все дополнительные положения и условия о покупке Товара и оказанию услуг, предоставляемых на Сайте.</li>
      <li>Информация, размещаемая на Сайте не должна истолковываться как изменение настоящего Соглашения.</li>
      <li>Администрация сайта имеет право в любое время без уведомления Пользователя вносить изменения в перечень Товаров и услуг, предлагаемых на Сайте, и (или) в цены, применимые к таким Товарам по их реализации и (или) оказываемым услугам.</li>
      <li>Документы, указанные в пунктах 5.11.1 - 5.11.4 настоящего Соглашения регулируют в соответствующей части и распространяют свое действие на использование Пользователем Сайта. В настоящее Соглашение включены следующие документы:
        <ol>
          <li>Политика конфиденциальности;</li>
          <li>Договор купли-продажи товаров дистанционным способом;</li>
          <li>Заявка на оформление заказа;</li>
          <li>Предложения и замечания.</li>
        </ol>
      </li>
      <li>Любой из документов, перечисленных в пункте 5.11. настоящего Соглашения может подлежать обновлению. Изменения вступают в силу с момента их опубликования на Сайте.</li>
    </ol>
  </li>
  <li>ОТВЕТСТВЕННОСТЬ
    <ol>
      <li>Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного нарушения любого положения настоящего Соглашения, а также вследствие несанкционированного доступа к коммуникациям другого Пользователя, Администрацией сайта не возмещаются.</li>
      <li>Администрация сайта не несет ответственности за:
        <ol>
          <li>Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах.</li>
          <li>Действия систем переводов, банков, платежных систем и за задержки связанные с их работой.</li>
          <li>Надлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых технических средств для его использования, а также не несет никаких обязательств по обеспечению пользователей такими средствами.</li>
        </ol>
      </li>
    </ol>
  </li>
  <li>НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ
    <ol>
      <li>Администрация сайта вправе раскрыть любую собранную о Пользователе данного Сайта информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного использования Сайта либо для установления (идентификации) Пользователя, который может нарушать или вмешиваться в права Администрации сайта или в права других Пользователей Сайта.</li>
      <li>Администрация сайта имеет право раскрыть любую информацию о Пользователе, которую посчитает необходимой для выполнения положений действующего законодательства или судебных решений, обеспечения выполнения условий настоящего Соглашения, защиты прав или безопасности ООО «НХЛ», Пользователей.</li>
      <li>Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации требует или разрешает такое раскрытие.</li>
      <li>Администрация сайта вправе без предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся в иных документах условия пользования Сайтом, а также в случае прекращения действия Сайта либо по причине технической неполадки или проблемы.</li>
      <li>Администрация сайта не несет ответственности перед Пользователем или третьими лицами за прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения или иного документа, содержащего условия пользования Сайтом.</li>
    </ol>
  </li>
  <li>РАЗРЕШЕНИЕ СПОРОВ
    <ol>
      <li>В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора).</li>
      <li>Получатель претензии в течение 30 календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</li>
      <li>При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим законодательством Российской Федерации.</li>
      <li>Любой иск в отношении условий использования Сайта должен быть предъявлен в течение 5 дней после возникновения оснований для иска, за исключением защиты авторских прав на охраняемые в соответствии с законодательством материалы Сайта. При нарушении условий данного пункта любой иск или основания для иска погашаются исковой давностью.</li>
    </ol>
  </li>
  <li>ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
    <ol>
      <li>Администрация сайта не принимает встречные предложения от Пользователя относительно изменений настоящего Пользовательского соглашения.</li>
      <li>Отзывы Пользователя, размещенные на Сайте, не являются конфиденциальной информацией и могут быть использованы Администрацией сайта без ограничений.</li>
    </ol>
  </li>
</ol>
</div>)