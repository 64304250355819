import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
  return (
    <section id="error-page">
      <h1>Oops!</h1>
      <p className='center'>Такой страницы не существует.</p>
      <div className="flex-row-space-around">
        <Link className="button" to="/articles">
          Статьи
        </Link>
        <Link className="button" to="/services">
          Услуги
        </Link>
        <Link className="button" to="/">
          Главная
        </Link>
      </div>
    </section>
  );
}
