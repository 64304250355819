import React from 'react';
import { useReadItemConfig } from '../../../Config/readItemConfig';
import { ConditionerContent, ConditionerFaq } from '../ArticlesInner';
import ArticlePageSample from '../../../02_samples/ArticlePageSample/ArticlePageSample';

export default function Conditioner() {
  const path = useReadItemConfig();
  return (
    <ArticlePageSample
      title={'Кондиционеры: особенности и виды'}
      description={
        'В этой статье вы найдете всю необходимую информацию о кондиционерах: от типов и особенностей до рекомендаций по установке и регулировке температуры. Узнайте, как выбрать наиболее энергоэффективный кондиционер для вашего дома и как снизить уровень шума от работы кондиционера.'
      }
      keywords={
        'кондиционеры, типы кондиционеров, установка кондиционера, регулировка температуры, энергоэффективность кондиционеров, шум кондиционеров, обогрев кондиционера, выбор кондиционера, мобильный кондиционер, климатическая техника'
      }
      faq={ConditionerFaq}
      content={ConditionerContent}
      path={path}
    />
  );
}
