import { Link } from 'react-router-dom';

export const HowToChooseContent = (
  <>
    <div className="enter-img center-item">
      <img
        className="article-img"
        src={require('../Articles/HowToChoose/pics/items.webp')}
        alt="holod-oborudovanie"
      />
      <img
        className="article-img-low"
        src={require('../Articles/HowToChoose/pics/items.webp')}
        alt="holod-oborudovanie"
      />
    </div>
    <p>
      Первое, с чего стоит начать свой выбор холодильного оборудования –
      необходимо запомнить следующее:
    </p>
    <ol>
      <li>
        Когда дело касается продуктов питания – это имеет прямое отношение к
        жизни и здоровью человека. Поэтому ни в коем случае нельзя допускать
        ошибки при выборе оборудования, в котором продукция будет храниться.
      </li>
      <li>
        Ваше оборудование всегда должно быть в идеальном состоянии, т.к. из-за
        его поломок Вы рискуете не только получить неприятности со стороны
        надзорных органов, но и потерять авторитет среди покупателей, что
        напрямую отразится на прибыльности и даже может привести к закрытию
        бизнеса.
      </li>
      <li>
        Системы, обеспечивающие холодоснабжение относятся к наиболее сложным
        устройствам климатической техники. Поэтому типовых решений в данном
        процессе практически не существует. Каждый конкретный объект требует
        индивидуального подхода с учетом специфических требований (ассортимент,
        особенность помещения и т.д.).
      </li>
    </ol>
    <span>Классификация холодильного оборудования</span>
    <br />
    <br />
    <p>
      Так как технический прогресс не стоит на месте, а ситуация на рынках
      стремительно меняется, то с некоторой долей условности все существующее
      холодильное оборудование делится на 2 класса:
    </p>
    <ol>
      <li>Промышленное холодильное оборудование</li>
      <li>Торговое холодильное оборудование</li>
    </ol>
    <p>
      К <em>промышленному холодильному оборудованию</em> можно отнести те
      агрегаты, которые используются для длительного хранения продукции, а также
      принимают участие в технологических производственных процессах. Таким
      образом, промышленное холодильное оборудование подразделяется на
      производственное и складское.
    </p>
    <p>
      Данный класс оборудования характеризуется высокой мощностью(более 10 кВт),
      быстрым созданием и поддержанием рабочей температуры в заявленном
      диапазоне с высокой точностью.
    </p>
    <p>
      К <em>торговому холодильному оборудованию</em> относятся те агрегаты,
      которые используются для кратковременного хранения охлажденной или
      замороженной продукции в магазинах и предприятиях общепита.
    </p>
    <p>
      Данный класс оборудования характеризуется меньшей мощностью по сравнению с
      промышленным холодильным оборудованием (в некоторых случаях используются и
      мощные машины), а также данное оборудование обеспечивает обзорность
      (покупатель должен видеть весь ассортимент) и интерактивность (доступ к
      продуктам должен быть свободный) для покупателя.
    </p>
    <br />
    <span>Классификация холодильных установок</span>
    <br />
    <br />
    <p>По уровню поддерживаемой температуры:</p>
    <ol>
      <li>
        Морозильные. Поддерживают температуру от -25° С. Используются для
        хранения заморозки;
      </li>
      <li>
        Низкотемпературные. Поддерживают температуру от -18 до -25° С.
        Используются для выкладки и сохранности заморозки и полуфабрикатов
      </li>
      <li>
        Комбинированные. Могут замораживать или охлаждать. Температурный режим
        регулируется в зависимость от объекта хранения;
      </li>
      <li>
        Среднетемпературные. Поддерживают температуру от 0 до +8° С.
        Используются для выкладки гастрономических изделий, молочной продукции.
      </li>
      <li>
        Высокотемпературные. Используется для выкладки шоколада, овощей,
        фруктов, кондитерских изделий.
      </li>
    </ol>
    <p>По типу холодообеспечения:</p>
    <ol>
      <li>Естественная циркуляция воздуха</li>
      <li>Принудительная циркуляция воздуха</li>
      <li>Встроенный холодильный агрегат.</li>
      <li>Выносной холодильный агрегат</li>
    </ol>
    <p>Необходимо более подробно остановиться на последних 2-х пунктах.</p>
    <p>
      Главным достоинством холодильного{' '}
      <em>оборудования со встроенным холодильным агрегатом</em> характеризуется
      своей компактностью, поэтому чаще всего такого вида оборудование
      используется на малых предприятиях, в магазинах с небольшой площадью и
      уличной торговле.{' '}
    </p>
    <p>
      Напротив, весомым недостатком этого типа является сравнительно низкая
      эффективность, а также ощутимый уровень шума от работы компрессора и
      необходимость отведение выделяемого им тепла. Также, необходимо отметить
      довольно малый ресурс работы и уменьшенный полезный объем помещения ввиду
      размещения компрессора внутри агрегата.
    </p>
    <p>
      В свою очередь, <em>оборудование с выносным холодильным агрегатом</em>{' '}
      является более эффективным решением ввиду как раз вынесения холодильного
      агрегата, либо подключения оборудования к централи. Централью (центральная
      холодильная машина) является агрегат, к которому при помощи трубопровода
      подключается сразу несколько единиц холодильного оборудования.{' '}
    </p>
    <p>
      Главным преимуществом такого оборудования является отсутствие шума и
      выброс тепла за пределы помещения, в котором оно находится. Необходимо
      отметить надежность, с которой работает такое оборудование ввиду
      возможности обеспечения бесперебойной работы всего холодильного
      оборудования даже в аварийных условиях.
    </p>
    <p>
      Однако, стоит отметить и недостаток такого решения – дополнительные
      расходы на установку и обслуживание.
    </p>
    <p>По герметичности:</p>
    <ol>
      <li>
        Закрытые. Оснащаются плотно закрывающимися распашными или раздвижными
        дверцами.
      </li>
      <li>
        Открытые. Поддержание заданной температуры осуществляется вентилятором.
      </li>
    </ol>
    <br />
    <span>Разновидности холодильного оборудования</span>
    <br />
    <br />
    <div className="flex-2-col">
      <div>
        <p style={{ color: 'blue' }}>Складское холодильное оборудование.</p>
        <img
          className="article-img-low"
          src={require('./HowToChoose/pics/sklad-obor.webp')}
          alt="sklad-obor"
        />
        <br />
        <p>
          Основное требование к такому оборудованию – поддержание необходимого
          температурного режима хранения продукции.
        </p>
        <p>
          Чаще всего, склады делят на среднетемпературные (от -5 до +14° С) и
          низкотемпературные (от -30 до -15° С) и холодоснабжение при этом
          обеспечивается оборудованием с выносными холодильными агрегатами.
        </p>
      </div>
      <img
        className="article-img"
        src={require('./HowToChoose/pics/sklad-obor.webp')}
        alt="sklad-obor"
      />
    </div>
    <br />
    <div className="flex-2-col">
      <img
        className="article-img"
        src={require('./HowToChoose/pics/hol-cam.webp')}
        alt="hol-cam"
      />
      <div>
        <p style={{ color: 'blue' }}>Холодильные камеры</p>
        <img
          className="article-img-low"
          src={require('./HowToChoose/pics/hol-cam.webp')}
          alt="hol-cam"
        />
        <br />
        <p>
          Применение данной разновидности холодильного оборудования – склады
          длительного хранения и распределительные центры, а также охлаждение и
          заморозка продукции в больших объемах (до десятков тонн в сутки).
          Эксплуатация таких камер происходит в цикличном режиме с чередованием
          загрузки и выгрузки продукции. Процесс термообработки занимает от
          нескольких часов до нескольких суток. Выполнены из теплоизоляционных
          панелей.
        </p>
        <p>
          На сегодняшний день существует огромное множество разновидностей
          холодильных камер, однако условно их можно разделить на следующие
          группы:
        </p>
        <ol>
          <li>
            Камеры от 6 до 20 м3. Оптимально для установки в торговых точках с
            небольшой площадью.
          </li>
          <li>
            Камеры от 50 до 100 м3. Как правило, высотой более 2-х метров.
          </li>
          <li>Камеры свыше 100 м3. Применение – производственные цели.</li>
        </ol>
      </div>
    </div>
    <br />
    <div className="flex-2-col">
      <div>
        <p style={{ color: 'blue' }}>Холодильные шкафы</p>
        <img
          className="article-img-low"
          src={require('./HowToChoose/pics/holod-shkaf.webp')}
          alt="holod-shkaf"
        />
        <br />
        <p>
          Предназначение данного оборудования – хранение и демонстрация готовых
          блюд, полуфабрикатов и т.п.{' '}
        </p>
        <p>
          Шкаф состоит из охлаждаемой камеры и машинного отделения. Корпус чаще
          всего стальной, комплектуется прозрачными стеклянными или глухими
          дверями. Внутри устанавливаются полки.
        </p>
        <p>Различаются по типу охлаждения:</p>
        <ol>
          <li>Статическое охлаждение</li>
          <li>Динамическое охлаждение</li>
        </ol>
        <p>
          В шкафах со статическим охлаждением перемещение полок внутри шкафа не
          разрешается ввиду их использования в качестве испарителей. Холод
          внутри перемещается естественным путем.
        </p>
        <p>
          В шкафах с динамическим охлаждением, которое обеспечено работой
          встроенных вентиляторов, холод распространяется равномерно, что
          гарантирует стабильность температуры даже при частом открытии дверей.
          Расстояние между полками может быть изменено.
        </p>
      </div>
      <img
        className="article-img"
        src={require('./HowToChoose/pics/holod-shkaf.webp')}
        alt="holod-shkaf"
      />
    </div>
    <br />
    <div className="flex-2-col">
      <img
        className="article-img"
        src={require('./HowToChoose/pics/skor-app.webp')}
        alt="skor-app"
      />
      <div>
        <p style={{ color: 'blue' }}>Скороморозильные аппараты</p>
        <img
          className="article-img-low"
          src={require('./HowToChoose/pics/skor-app.webp')}
          alt="skor-app"
        />
        <br />
        <p>
          Данная разновидность холодильного оборудования применяется
          непосредственно в промышленных целях. Задача такого агрегата – быстрая
          заморозка продукции.
        </p>
        <p>Скороморозильные аппараты подразделяются на:</p>
        <ol>
          <li>Плиточные</li>
          <li>Спиральные</li>
        </ol>
        <p>
          Плиточные используются для переработки мясной продукции, мясных
          полуфабрикатов, рыбной продукции, морепродуктов, плодовоовощной
          продукции. Процесс заморозки длится от 1,5 до 2-х часов. В результате
          получаем блоки замороженной продукции единого типоразмера.
        </p>
        <p>
          Спиральные используются при производстве продукции быстрого
          приготовления (пельмени, блины) и полуфабрикатов, а также для
          охлаждения кондитерской и хлебобулочной продукции.
        </p>
      </div>
    </div>
    <br />
    <div className="flex-2-col">
      <div>
        <p style={{ color: 'blue' }}>Камеры шоковой заморозки</p>
        <img
          className="article-img-low"
          src={require('./HowToChoose/pics/kamer-shok.webp')}
          alt="kamer-shok"
        />
        <br />
        <p>Предназначены для ускорения всех этапов замораживания продукта.</p>
        <p>
          Использование данного холодильного оборудования гарантирует высокое
          качество выходной продукции с сохранением вкусовых качеств и полезных
          свойств после процесса разморозки.{' '}
        </p>
        <p>К преимуществам шоковой заморозки также можно отнести:</p>
        <ol>
          <li>Снижение себестоимости продукции ввиду экономии на скорости</li>
          <li>Отказ от вредных консервантов</li>
          <li>Увеличенное время хранения</li>
          <li>
            Возможность построения более дешевого оптимизированного
            логистического процесса
          </li>
          <li>
            Более быстрая окупаемость по сравнению с традиционными решениями
          </li>
        </ol>
      </div>
      <img
        className="article-img"
        src={require('./HowToChoose/pics/kamer-shok.webp')}
        alt="kamer-shok"
      />
    </div>
    <br />
    <div className="flex-2-col">
      <img
        className="article-img"
        src={require('./HowToChoose/pics/ldogen.webp')}
        alt="Ldogen"
      />
      <div>
        <p style={{ color: 'blue' }}>Льдогенераторы</p>
        <img
          className="article-img-low"
          src={require('./HowToChoose/pics/ldogen.webp')}
          alt="Ldogen"
        />
        <br />
        <p>
          Как очевидно из названия – данное оборудование предназначено для
          генерации льда самой разнообразной формы:
        </p>
        <ol>
          <li>Чешуйчатый</li>
          <li>Гранулированный</li>
          <li>Мелкодробленый</li>
          <li>Кубиковый</li>
          <li>Пластинчатый</li>
          <li>Жидкий</li>
        </ol>
        <p>
          Каждая из этих форм в той или иной степени способствует сохранности
          продукции или ее охлаждению. Может быть применено для решения самых
          разнообразных проблем от подстилки для размещения свежей продукции до
          транспортировки скоропорта.
        </p>
        <p>
          Данная разновидность холодильного оборудование имеет широкий спектр
          разнообразия форм-фактора, что позволяет с удобством располагать
          данный агрегат на любой площади, однако стоит участь, что обычно
          требуется подключение к водоснабжению и канализации.
        </p>
      </div>
    </div>
    <br />
    <div className="flex-2-col">
      <div>
        <p style={{ color: 'blue' }}>Морозильные лари</p>
        <img
          className="article-img-low"
          src={require('./HowToChoose/pics/lar.webp')}
          alt="lar"
        />
        <br />
        <p>
          Предназначены для длительного хранения скоропорта, мясной продукции,
          фруктов, полуфабрикатов, которые прошли предварительную заморозку.
          Может выполнять роль предпродажной демонстрации.
        </p>
        <p>Устройство компактно, мобильно и с ним удобно взаимодействовать.</p>
        <p>Могут иметь прозрачную или глухую металлическую крышку.</p>
        <p>Идеально для магазинов самообслуживания. </p>
      </div>
      <img
        className="article-img"
        src={require('./HowToChoose/pics/lar.webp')}
        alt="lar"
      />
    </div>
    <br />
    <div className="flex-2-col">
      <img
        className="article-img"
        src={require('./HowToChoose/pics/moroz-bonet.webp')}
        alt="moroz-bonet"
      />
      <div>
        <p style={{ color: 'blue' }}>Морозильные бонеты</p>
        <img
          className="article-img-low"
          src={require('./HowToChoose/pics/moroz-bonet.webp')}
          alt="moroz-bonet"
        />
        <br />
        <p>
          Применение данная разновидность холодильного оборудование находит чаще
          всего в магазинах самообслуживания, ресторанах, супермаркетах и т.д.,
          так как они:
        </p>
        <ol>
          <li>Обеспечивают оптимальный температурный режим</li>
          <li>Удобно демонстрируют продукты покупателям</li>
          <li>Имеют легкий доступ к содержимому</li>
        </ol>
        <p>По температурному режиму подразделяются:</p>
        <ol>
          <li>Низкотемпературные (от -18 до -25° С)</li>
          <li>Среднетемпературные (от -5 до +5° С)</li>
        </ol>
        <p>По организации доступа делятся на:</p>
        <ol>
          <li>Открытые</li>
          <li>
            Закрытые. Как правило, оснащаются раздвижной или откидной крышкой с
            хорошими теплоизоляционными качествами.
          </li>
        </ol>
      </div>
    </div>
    <br />
    <div className="flex-2-col">
      <div>
        <p style={{ color: 'blue' }}>Холодильные витрины и прилавки</p>
        <img
          className="article-img-low"
          src={require('./HowToChoose/pics/pril-vitr.webp')}
          alt="pril-vitr"
        />
        <br />
        <p>
          По сути - самая распространенная разновидность холодильного
          оборудования среди магазинов розничной торговли и предприятий
          общественного питания. Имеют обширный ассортимент марок, брендов,
          моделей и форм-факторов.
        </p>
        <p>Различаются по температурному режиму, конструкции системы.</p>
        <p>По температурному режиму:</p>
        <ol>
          <li>Низкотемпературные (от -6 до 0° С)</li>
          <li>Среднетемпературные (от +1 до +7° С)</li>
          <li>Универсальные (от -6 до +6° С)</li>
          <li>Кондитерские (от +3 до +8° С)</li>
        </ol>
        <p>По конструкции системы:</p>
        <ol>
          <li>Нижнее расположение охлаждающей поверхности</li>
          <li>Заднее расположение охлаждающей поверхности</li>
          <li>Равномерное охлаждение</li>
        </ol>
      </div>
      <img
        className="article-img"
        src={require('./HowToChoose/pics/pril-vitr.webp')}
        alt="pril-vitr"
      />
    </div>
    <br />
    <div className="flex-2-col">
      <img
        className="article-img"
        src={require('./HowToChoose/pics/holod-stol.webp')}
        alt="holod-stol"
      />
      <div>
        <p style={{ color: 'blue' }}>Холодильные столы</p>
        <img
          className="article-img-low"
          src={require('./HowToChoose/pics/holod-stol.webp')}
          alt="holod-stol"
        />
        <br />
        <p>
          Самое распространенное применение данная разновидность получила у
          предприятий общественного питания, так как данный агрегат представляет
          собой обычный стол с функцией холодильника. Оно оборудовано
          охлаждающей поверхностью, которая способствует увеличению времени
          нахождения продукции вне пределов основного холодильника.{' '}
        </p>
        <p>
          Также, данный агрегат может быть оснащен внутренней охлаждающей
          емкостью. Температура такой емкости может изменяться в пределах от -2
          до +10° С для среднетемпературных моделей, а также от -20 до -10° С
          для низкотемпературных. Количество секций также может варьироваться.
        </p>
      </div>
    </div>
    <br />
    <div className="flex-2-col">
      <div>
        <p style={{ color: 'blue' }}>Холодильные горки</p>
        <img
          className="article-img-low"
          src={require('./HowToChoose/pics/holod-gorka.webp')}
          alt="holod-gorka"
        />
        <br />
        <p>
          Используются для выкладки продукции. Могут быть оснащены подсветкой
          или витринным зеркалом. В зависимости от характеристик подразделяются
          на:
        </p>
        <ol>
          <li>
            Гастрономические. Используется для выкладки молочной продукции и
            гастрономии. Температурный режим – до +7° С
          </li>
          <li>
            Мясные. Также могут быть использованы для выкладки рыбной продукции.
            Температурный режим – не более 2° С
          </li>
          <li>
            Фруктовые. Выкладка фруктов и овощей. Температурный диапазон – от +4
            до +10° С
          </li>
        </ol>
        <p>
          В зависимости от расположения могут быть островными и пристенными.
        </p>
      </div>
      <img
        className="article-img"
        src={require('./HowToChoose/pics/holod-gorka.webp')}
        alt="holod-gorka"
      />
    </div>
    <br />
    <span>Как выбрать холодильное оборудование</span>
    <br />
    <br />
    <p>
      Каждый отдельно взятый холодильный агрегат предназначен для своих
      определенных целей и имеет ряд характеристик, которые необходимо учитывать
      при выборе оборудования для своих целей. Для того, чтобы правильно
      определиться с тем, какой именно тип холодильника Вам необходим, следует
      учесть следующее:
    </p>
    <div className="flex-2-col">
      <ol>
        <li>Ассортимент продукции</li>
        <li>Габариты помещения</li>
        <li>Температурный режим свежести продукта</li>
        <li>Необходимая мощность</li>
        <li>Функциональное назначение</li>
        <li>
          Условия хранения – заморозка, охлаждение, нейтральная температура
        </li>
        <li>Обзорность </li>
        <li>Интерактивность </li>
      </ol>
      <img
        className="article-img"
        src={require('./pics/check.webp')}
        alt="check"
      />
    </div>
    <p>
      Если Вы ищете холодильное оборудование в Нижнем Новгороде, или хотели бы
      заказать установку или{' '}
      <Link className="hyper" to="/services/remont-holodilnogo-oborudovaniya">
        ремонт холодильного оборудования
      </Link>
      , то Вы можете связаться с нашими специалистами и получить исчерпывающую
      информацию по интересующим Вас вопросам.
    </p>
    <br />
    <br />
  </>
);

export const WhatIsChillerContent = (
  <>
    <div className="enter-img center-item">
      <img
        className="article-img"
        src={require('./WhatIsChiller/pics/chiller.webp')}
        alt="chiller"
      />
      <img
        className="article-img-low"
        src={require('./WhatIsChiller/pics/chiller.webp')}
        alt="chiller"
      />
    </div>
    <p>
      Чиллер – это холодильный агрегат, который предназначен для охлаждения или
      подогрева различных жидкостей, которые могут быть использованы в качестве
      переносчика тепла.
    </p>
    <p>
      Данное холодильное оборудование применяется в тех случаях, когда
      необходимо поддерживать стабильную температуру для каких-либо целей. Чаще
      всего, чиллеры используют в таких отраслях, как:
    </p>
    <ol>
      <li>Промышленность</li>
      <li>Пищевая отрасль</li>
      <li>Фармокология</li>
      <li>Машиностроительная отрасль</li>
      <li>Химическая отрасль</li>
      <li>Ликероводочная отрасль и т.п.</li>
    </ol>
    <p>
      Также, данный тип холодильных машин способен решать большое количество
      задач в бытовых и развлекательных сферах, медицине и коммерции.
    </p>
    <br />
    <span>Базовое устройство</span>
    <br />
    <br />
    <div className="flex-2-col">
      <div>
        <p>Чиллер в своей базе комплектуется следующим набором элементов:</p>
        <ol>
          <li>
            Компрессор – выполняет задачи по обеспечению мощностью холодильного
            цикла.{' '}
          </li>
          <li>
            Конденсатор – преобразование газа(пара) в жидкость, отвод тепла и
            конденсация хладагента.
          </li>
          <li>Испаритель – преобразует жидкость в пар.</li>
        </ol>
        <p>
          Также, конструкцией предусмотрено наличие расширительного клапана
          (дозирует количество хладагента) и 2-х контуров (вода и хладагент).
        </p>
      </div>
      <img
        className="article-img"
        src={require('./WhatIsChiller/pics/structure.webp')}
        alt="structure"
      />
      <img
        className="article-img-low"
        src={require('./WhatIsChiller/pics/structure.webp')}
        alt="structure"
      />
    </div>
    <br />
    <span>Принцип работы</span>
    <br />
    <br />
    <p>Принцип работы чиллера достаточно простой. </p>
    <div className="flex-2-col">
      <img
        className="article-img"
        src={require('./WhatIsChiller/pics/chil-work.webp')}
        alt="chill-work"
      />
      <img
        className="article-img-low"
        src={require('./WhatIsChiller/pics/chil-work.webp')}
        alt="chill-work"
      />
      <div>
        <p>
          Холодный хладагент низкого давления сначала попадает в компрессор,
          задача которого повысить давление и температур.
        </p>{' '}
        <p>
          Затем в газообразном виде попадает в конденсатор, где отдает тепло и
          превращается в жидкость.
        </p>{' '}
        <p>
          После чего, жидкий хладагент под давлением поступает в расширительный
          клапан и через него попадает в испаритель.
        </p>{' '}
        <p>
          После попадания в испаритель, давление хладагента резко падает,
          хладагент закипает, поглощая при этом тепло от технологической воды.
        </p>{' '}
        <p>
          Затем, хладагент превращается в газ низкого давления и цикл начинается
          с начала.
        </p>
      </div>
    </div>
    <br />
    <span>Типы (виды) чиллеров</span>
    <br />
    <br />
    <p>
      На сегодняшний день, на рынке можно найти огромное количество промышленных
      чиллеров, которые различаются между собой характеристиками и
      особенностями. Однако, условно их все можно разделить на несколько больших
      групп по критериям основных узлов:
    </p>
    <ol>
      <li>
        Конденсатор
        <ul>
          <li>
            Воздушный{' '}
            <ul>
              <li>Встроенный конденсатор</li>
              <li>Выносной конденсатор</li>
            </ul>
          </li>
          <li>Водяной</li>
        </ul>
      </li>
      <li>
        Работа на обогрев{' '}
        <ul>
          <li>Есть (в конструкции предусмотрен тепловой насос)</li>
          <li>Отсутствует</li>
        </ul>
      </li>
      <li>
        Компрессор{' '}
        <ul>
          <li>Винтовой</li>
          <li>Поршневой</li>
          <li>Роторный</li>
          <li>Спиральный</li>
        </ul>
      </li>
      <li>
        Вентиляторы конденсатного блока{' '}
        <ul>
          <li>
            Центробежный вентилятор (мощный напор воздуха, шум, высокая
            производительность при малых размерах)
          </li>
          <li>Осевой вентилятор (дешевле, слабый напор воздуха, тихий)</li>
        </ul>
      </li>
      <li>
        Холодильный цикл{' '}
        <ul>
          <li>Парокомпрессионный</li>
          <li>Абсорбционный</li>
        </ul>
      </li>
    </ol>
    <span>Особенности</span>
    <br />
    <br />
    <p>
      Отличительными чертами современных чиллеров можно считать – экологичность
      и бесшумную работу. Производители с каждым годом выпускают новые модели,
      которые становятся все более удобными в эксплуатации.{' '}
    </p>
    <div className="flex-2-col">
      <div>
        <p>Основными преимуществами чиллера являются:</p>
        <ol>
          <li>Безопасность</li>
          <li>
            Возможность осуществления охлаждения на любом расстоянии (зависит от
            мощности циркуляционного насоса)
          </li>
          <li>Экономичность</li>
          <li>Возможность установки вне помещения</li>
          <li>Погодные условия не влияют на работу</li>
          <li>Использование в помещениях с минимальной площадью</li>
        </ol>
      </div>
      <img
        className="article-img"
        src={require('./WhatIsChiller/pics/like.webp')}
        alt="like"
      />
      <img
        className="article-img-low"
        src={require('./WhatIsChiller/pics/like.webp')}
        alt="like"
      />
    </div>
    <p>
      Главным достоинством чиллера по праву является возможность подачи
      теплоносителя на большие расстояния, что выгодно отличает донное
      холодильное оборудование от классических сплит-систем. Только одно это
      дает возможность использовать данный агрегат в офисах, магазинах и даже
      торговых центрах. Также, немаловажным является тот факт, что при
      использовании чиллера можно сократить расходы на фреон, который стоит
      значительно дороже, чем хладагенты, используемые в работе описываемого
      холодильного оборудования.
    </p>
    <div className="flex-2-col">
      <div>
        <p>Однако, чиллеры имеют и свои недостатки:</p>
        <ol>
          <li>Довольно высокое потребление энергии</li>
          <li>Стоимость по сравнению со сплит-системами выше</li>
          <li>Большой вес</li>
          <li>Габариты</li>
        </ol>
      </div>
      <img
        className="article-img"
        src={require('./WhatIsChiller/pics/dislike.webp')}
        alt="dislike"
      />
      <img
        className="article-img-low"
        src={require('./WhatIsChiller/pics/dislike.webp')}
        alt="dislike"
      />
    </div>
    <p>
      Также, сам выбор чиллера имеет свои определенные трудности. Без совета
      опытного эксперта довольно трудно будет принять верное решение.
    </p>
    <br />
    <span>Как выбрать чиллер</span>
    <br />
    <br />
    <div className="flex-2-col">
      {' '}
      <div>
        <p>
          В первую очередь, необходимо определиться с типом чиллера, исходя из
          целей и требований к эксплуатации. Для этого, нужно принять во
          внимание следующее:
        </p>
        <ol>
          <li>Тип теплоносителя</li>
          <li>Охлаждение или охлаждение + нагрев</li>
          <li>Сезонность эксплуатации</li>
          <li>
            Расположение чиллера и гидромодуля в одном корпусе/ разных корпусах
          </li>
          <li>Температурный режим</li>
          <li>Место размещения</li>
        </ol>
      </div>
      <img
        className="article-img"
        src={require('./pics/check.webp')}
        alt="check"
      />
      <img
        className="article-img-low"
        src={require('./pics/check.webp')}
        alt="check"
      />
    </div>
    <p>
      При необходимости размещения чиллера внутри помещения, учитываются
      габариты агрегата и пространства для проведения ремонтных/профилактических
      работ, а также температура внутри помещения (влияет на давление
      конденсации).
    </p>
    <p>
      После учета всех нюансов, необходимо произвести расчет мощности и, при
      необходимости, подбор комплектующих. Цена на итоговый вариант, как
      правило, напрямую зависит от комплектации и мощности оборудования.
    </p>
    <p>
      Если Вы ищете чиллер в Нижнем Новгороде или хотите заказать установку или{' '}
      <Link className="hyper" to="/services/remont-chillera">
        ремонт чиллера
      </Link>
      , то Вы можете связаться с нашими специалистами и подобрать для себя
      необходимое оборудование.
    </p>
    <br />
    <br />
  </>
);

export const ShockFreezingContent = (
  <>
    <div className="enter-img center-item">
      <img
        className="article-img"
        src={require('../Articles/HowToChoose/pics/kamer-shok.webp')}
        alt="chiller"
      />
      <img
        className="article-img-low"
        src={require('../Articles/HowToChoose/pics/kamer-shok.webp')}
        alt="chiller"
      />
    </div>
    <p>
      Шоковая заморозка – это технология замораживания продуктов питания,
      имеющая широкое распространение в пищевой промышленности и коммерции.
      Представляет собой ускоренную динамику охлаждения продукции, которая
      занимает не более 4 часов. Данная технология имеет достаточно много
      отличительных черт, о которых мы Вам расскажем.
    </p>
    <p>
      В основе данной технологии лежит поэтапное воздействие на продукцию
      низкими температурами в разных диапазонах. Выделяют 3 основных:
    </p>
    <ol>
      <li>
        Охлаждение (от +200 С до 00 С). Целью данного этапа является забор тепла
        от продукта без нарушения качества и структуры объекта.
      </li>
      <li>
        Замораживание (от 00 С до -50 С). На данном этапе происходит
        кристаллизация жидких фракций продукта.
      </li>
      <li>
        Заморозка (от -50 С до -180 С). Цель данного этапа – довод продукта до
        необходимой температуры хранения.
      </li>
    </ol>
    <p>
      Основная идея данной технологии заключается в том, что ускоренный процесс
      заморозки позволяет уменьшить размеры образующихся кристаллов льда. Суть в
      том, что чем больше данные кристаллы, тем больше структурных изменений
      происходит в продукте и тем меньше его вкусовые качества после разморозки.{' '}
    </p>
    <p>
      Ускорение происходит за счет очень низкой температуры (до -35) и
      интенсивного вентилирования внутри камеры.
    </p>
    <br />
    <span>Сфера применения</span>
    <br />
    <br />
    <p>
      Данная технология является тем решением, которое можно применять везде,
      где может потребоваться такой процесс, как заморозка. Как уже говорилось
      выше, шоковая заморозка чаще всего применяется в пищевой промышленности и
      коммерческом секторе.
    </p>
    <p>Камеры шоковой заморозки применяют для следующих целей:</p>
    <ol>
      <li>Заморозка мясных, рыбных изделий и полуфабрикатов</li>
      <li>Заморозка свежих продуктов – ягоды, овощи, фрукты и т.д.</li>
      <li>Заморозка готовой продукции. Очень актуально для кондитеров.</li>
    </ol>
    <span>Достоинства шоковой заморозки</span>
    <br />
    <br />
    <p>
      Косвенно мы уже затрагивали данную тему{' '}
      <Link className="hyper" to="/articles/kak-vibrat-holodilnoe-oborudovanie">
        в этой статье
      </Link>
      . Стоит отметить, что перечисленные там преимущества имеют довольно
      большой вес для того, чтобы сделать выбор в пользу шоковой заморозки.
      Однако, есть и еще несколько плюсов:
    </p>
    <ol>
      <li>Уменьшение потери продукта. Ввиду специфики технологии.</li>
      <li>
        Компактность оборудования. Размещение одной камеры шоковой заморозки
        способно заменить несколько морозильных камер.
      </li>
      <li>Возможность экономии на сокращении производственного персонала</li>
      <li>
        Эксплуатация современного оборудования очень проста. Автоматика сама
        сделает всю работу, достаточно просто выбрать необходимый режим.
      </li>
      <li>
        Улучшение бактериологической среды. Микроорганизмы и бактерии,
        присутствующие в продукте не успевают адаптироваться к быстро
        изменяющимся условиям окружающей среды и погибают.
      </li>
    </ol>
    <span>Оборудование для шоковой заморозки</span>
    <br />
    <br />
    <p>
      Холодильное оборудования данной технологии представляет собой стационарную
      машину, стены которой изготавливаются из теплоизоляционных
      пенополиуретановых панелей, толщиной от 12 до 15 сантиметров. Система
      состоит из компрессора, воздухоохладителя, конденсатора и блока
      управления.
    </p>
    <p>
      В стандартной комплектации есть герметичные двери, внутреннее освещение и
      устройство для слива воды. По желанию, холодильную машину можно
      оборудовать пандусом.
    </p>
    <br />
    <span>Виды оборудования</span>
    <br />
    <br />
    <p>
      Все разнообразие холодильного оборудования, работающего на технологии
      шоковой заморозки можно разделить на 3 типа в зависимости от принципа
      работы камеры:
    </p>
    <ol>
      <li>Воздух</li>
      <li>Контакт</li>
      <li>Бесконтакт</li>
    </ol>
    <p>Воздух</p>
    <br />
    <p>Хладоносителем является воздух. По конструкции бывают:</p>
    <ol>
      <li>
        Туннельные. Продукция размещается на стеллажах, работа установки может
        быть периодической или непрерывной.
      </li>
      <li>Конвейерные. Продукция перемещается на специальном транспортере. </li>
      <li>
        Флюидизационные. Продукция помещается под постоянных поток воздуха.
      </li>
    </ol>
    <p>Бесконтакт</p>
    <br />
    <p>Используется хладагент. Подразделяются на:</p>
    <ol>
      <li>
        Плиточные. Продукция размещается между 2-я плитами, при помощи которых
        происходит процесс заморозки.
      </li>
      <li>Роторные. Плиты располагаются радиально.</li>
      <li>
        Барабанные. Представляют собой специальный крутящийся барабан с
        хладагентом внутри. Продукция помещается в рабочую зону на транспортере,
        прилипает к барабану и замораживается. Для последующей обработки,
        замороженный продукт счищается с барабана на конвейер ножом.
      </li>
    </ol>
    <p>Контакт.</p>
    <br />
    <p>
      Данный вид оборудования подразумевает непосредственный контакт
      хладоносителя и продукции. Подразделяются на:
    </p>
    <ol>
      <li>
        Фреоновые. Используется очищенный фреон. Во время процесса заморозки
        хладагент испаряется и через конденсатор снова возвращается в жидкое
        состояние.
      </li>
      <li>Криогенные. Используется жидкий азот.</li>
      <li>
        Углекислотные. Используется углекислота в газообразном и твердом виде.
      </li>
    </ol>
    <p>
      С точки зрения коммерции, оборудование для шоковой заморозки можно
      разделить на:
    </p>
    <ol>
      <li>
        Шкафы шоковой заморозки. Производительность – до 800 килограмм в сутки.
        Диапазон температур - -270 С до -320 С
      </li>
      <li>
        Камера шоковой заморозки с одной тележкой. Производительность – до 1.3
        тонны. Диапазон температур - -320 С до -400 С
      </li>
      <li>
        Камера шоковой заморозки с несколькими тележками. Производительность
        увеличивается в зависимости от количества тележек, может достигать до 8
        тонн.
      </li>
      <li>
        Камеры флюидизационной, конвейерной заморозки. Производительность до 48
        тонн. Диапазон температур - -400 С до -450 С
      </li>
      <li>
        Криогенные камеры. Диапазон температур - -600 С до -1200 С. Максимально
        быстрое замерзание без потери качества.
      </li>
    </ol>
    <span>Как выбрать оборудование для шоковой заморозки</span>
    <br />
    <br />
    <p>
      Из вышесказанного сам собой напрашивается очевидный список требований, на
      основе которого необходимо делать выбор:
    </p>
    <ol>
      <li>Количество продукции. </li>
      <li>Место для размещения.</li>
      <li>Температурный режим.</li>
    </ol>
    <p>
      Если Вы ищете оборудование для шоковой заморозки в Нижнем Новгороде, или
      хотели бы заказать установку или{' '}
      <Link className="hyper" to="/services/remont-kamer-shokovoy-zamorozki">
        ремонт
      </Link>{' '}
      камер шоковой заморозки, то Вы можете связаться с нашими специалистами и
      подобрать для себя необходимое оборудование.
    </p>
    <br />
    <br />
  </>
);

export const ShockFreezingFaq = [
  {
    title:
      '1. Какой минимальный размер оборудования для шоковой заморозки нужен для малого предприятия?',
    content:
      'Минимальный размер оборудования для шоковой заморозки зависит от объема производства и потребностей компании. Малым предприятиям, которые производят небольшое количество продукции, может быть достаточно маленького замораживающего шкафа.',
  },
  {
    title: '2. Какие продукты можно замораживать шоковой заморозкой?',
    content:
      'Шоковую заморозку можно использовать для замораживания широкого спектра продуктов, включая мясо, рыбу, овощи, фрукты, выпечку и тесто. Однако не все продукты подходят для шоковой заморозки, так как некоторые продукты могут потерять свою текстуру или стать грубыми после замораживания.',
  },
  {
    title:
      '3. Какие технические характеристики следует учитывать при выборе оборудования для шоковой заморозки?',
    content: (
      <>
        При выборе оборудования для шоковой заморозки следует обратить внимание
        на следующие технические характеристики:
        <ul>
          <li>Производительность</li>
          <li>Мощность</li>
          <li>Время замораживания</li>
          <li>Тип охлаждения</li>
          <li>Размеры оборудования</li>
          <li>Наличие дополнительных функций</li>
        </ul>
      </>
    ),
  },
  {
    title: '4. Как правильно хранить замороженные продукты?',
    content: (
      <>
        Для правильного хранения замороженных продуктов следует учитывать
        следующие рекомендации:
        <ul>
          <li>Хранить продукты в герметичной упаковке</li>
          <li>Хранить продукты при температуре не выше -18°С</li>
          <li>Избегать повторного замораживания продуктов</li>
        </ul>
      </>
    ),
  },
];

export const ConditionerContent = (
<>
<div className="enter-img center-item">
      <img
        className="article-img"
        src={require('./Conditioner/pics/conditioner.webp')}
        alt="chiller"
      />
      <img
        className="article-img-low"
        src={require('./Conditioner/pics/conditioner.webp')}
        alt="chiller"
      />
    </div>
    <p>На сегодняшний день, кондиционеры являются неотъемлемой частью нашей жизни. Данный вид климатической техники больше всего проявляет свою актуальность в жаркие месяцы. 
В данной статье мы рассмотрим особенности и виды кондиционеров, а также поднимем вопрос "как правильно выбрать кондиционер при покупке?".
</p><br/>
<span>Особенности кондиционеров</span>
<ol>
  <li><p>Регулировка температуры</p><p>Современное климатическое оборудование оснащено возможностью регулирования температуры.</p></li>
  <li><p>Очистка воздуха</p><p>Кондиционеры могут удалять из воздуха пыль, пыльцу, бактерии и другие загрязнения, что может быть полезно для людей, страдающих от аллергии.</p></li>
  <li><p>Увлажнение</p><p>Некоторые виды кондиционеров могут увлажнять воздух. Данная особенность очень полезна во время отопительного сезона, когда воздух в помещении становится сухим.</p></li>
  <li><p>Режимы работы</p><p>Чаще всего, у современных кондиционеров есть несколько режимов работы: охлаждение, обогрев, вентиляция, осушение. Это позволяет использовать технику в течение всего календарного года, а не только в жаркий сезон.</p></li>
</ol>
<span>Виды кондиционеров</span>
<div className='flex-2-col'><div><p><strong>Оконные кондиционеры</strong></p>
<p>Оконные кондиционеры, как следует из названия, устанавливаются в окнах. Их особенность заключается в том, что такой вид холодильного оборудования способен охлаждать только одно помещение. 
Данный вид кондиционеров не занимает много места и может быть относительно дешевым, однако он не подходит для использования в больших помещениях.
</p></div><img
        className="article-img"
        src={require('./Conditioner/pics/win-cond.webp')}
        alt="win-cond"
      />
      <img
        className="article-img-low"
        src={require('./Conditioner/pics/win-cond.webp')}
        alt="win-cond"
      /></div>
<div className="flex-2-col"><img
        className="article-img"
        src={require('./Conditioner/pics/wall-cond.webp')}
        alt="wall-cond"
      />
     <div>

<p><strong>Настенные кондиционеры</strong></p>
<p>Данный вид кондиционеров устанавливается на стену и, в отличии от предыдущего, может охлаждать одно или несколько помещений. Обладают более высокой мощностью, чем оконные, и могут использоваться для охлаждения помещений с большими габаритами, например офисы или магазины. Настенные кондиционеры также более функциональны и в своем арсенале могут иметь режимы обогрева и увлажнения воздуха.</p></div> <img
        className="article-img-low"
        src={require('./Conditioner/pics/wall-cond.webp')}
        alt="wall-cond"
      /></div>

<div className="flex-2-col"><div><p><strong>Мобильные кондиционеры</strong></p>
<p>Мобильные кондиционеры представляют собой ящик на колесиках и их можно перемещать из одного помещения в другое.</p>
<p>Особенностью конструкции является наличие гибкого шланга, через который выводится горячий воздух. Обычно такой шланг выводят наружу через окно.
Больше подходят для временного использования.
</p></div><img
        className="article-img"
        src={require('./Conditioner/pics/mob-cond.webp')}
        alt="mob-cond"
      />
      <img
        className="article-img-low"
        src={require('./Conditioner/pics/mob-cond.webp')}
        alt="mob-cond"
      /></div>

<div className="flex-2-col"><img
        className="article-img"
        src={require('./Conditioner/pics/kas-cond.webp')}
        alt="kas-cond"
      />
      <div><p><strong>Кассетные кондиционеры</strong></p>
<p>Кассетные кондиционеры дороже других типов кондиционеров, однако они более эффективны с точки зрения равномерности распределения прохладного воздуха внутри помещения. Большая эффективность достигается из-за особенности монтажа в потолок.</p></div><img
        className="article-img-low"
        src={require('./Conditioner/pics/kas-cond.webp')}
        alt="kas-cond"
      /></div>

<div className="flex-2-col"><div><p><strong>Мультизональные кондиционеры</strong></p>
<p>Мультизональные кондиционеры (сплит-система) могут охлаждать несколько помещений одновременно. Они состоят из одной внешней и нескольких внутренних блоков, каждый из которых может работать автономно. Данная особенность делает их идеальным решением для крупных домов или квартир с несколькими комнатами.</p></div><img
        className="article-img"
        src={require('./Conditioner/pics/mult-cond.webp')}
        alt="mult-cond"
      />
      <img
        className="article-img-low"
        src={require('./Conditioner/pics/mult-cond.webp')}
        alt="mult-cond"
      /></div>
<br/>
<br/>
<span>Как выбрать подходящий кондиционер?</span>
<p>При выборе кондиционера следует учитывать несколько факторов: </p>
<ol>
  <li>размер помещения,</li>
  <li>количество людей, находящихся в нем,</li>
  <li>климатические условия</li>
  <li>мощность</li>
  <li>уровень шума</li>
  <li>энергоэффективность</li>
  <li>режимы работы</li>
  <li>бюджет. </li>
</ol>
<p>Оконные и настенные кондиционеры обычно наиболее доступны по цене и легче устанавливаются, однако они менее мощные и не справляются с охлаждением больших помещений. Мобильные кондиционеры являются хорошим вариантом для временного использования.</p>
<p>Кассетные и мультизональные кондиционеры по цене более дорогие, однако они могут более эффективны и обеспечивают более равномерное распределение прохладного воздуха. Они также более энергоэффективны, что позволяет сэкономить на счетах за электроэнергию.</p>
<p>
      Если Вы ищете кондиционер в Нижнем Новгороде, или
      хотели бы заказать установку или{' '}
      {/* <Link className="hyper" to="/services/remont-kamer-shokovoy-zamorozki">
        ремонт
      </Link>{' '} */} ремонт
      кондиционеров, то Вы можете связаться с нашими специалистами и
      подобрать для себя необходимое оборудование.
    </p>
    <br />
    <br />
</>)

export const ConditionerFaq = [
  {
    title:'1.	Какой тип кондиционера лучше всего подходит для больших помещений? .',
    content:'Кассетные и мультизональные кондиционеры',
  },
  {
    title:'2.	Какой тип кондиционера лучше всего подходит для временного использования? ',
    content:'Мобильные кондиционеры.',
  },
  {
    title:'3.	Какова энергоэффективность кондиционеров? ',
    content:'Кондиционеры могут иметь различные уровни энергоэффективности - чем выше рейтинг энергоэффективности, тем меньше электроэнергии они потребляют.',
  },
  {
    title:'4.	Как снизить уровень шума кондиционера? ',
    content:'Чтобы снизить уровень шума кондиционера, следует выбирать модели с низким уровнем децибел и устанавливать их на достаточном расстоянии от места отдыха.',
  },
  {
    title:'5.	Могут ли кондиционеры использоваться как обогреватели? ',
    content:'Да, некоторые кондиционеры имеют режим обогрева.',
  },
]

export const LdogenContent = (<><div className="enter-img center-item">
<img
  className="article-img"
  src={require('../Articles/HowToChoose/pics/ldogen.webp')}
  alt="chiller"
/>
<img
  className="article-img-low"
  src={require('../Articles/HowToChoose/pics/ldogen.webp')}
  alt="chiller"
/>
</div>
<p>Льдогенераторы - это устройства, которые используются для производства льда. Они могут быть использованы в различных областях, включая рестораны, кафе, бары, гостиницы, больницы, а также в домашних условиях. В этой статье мы рассмотрим различные типы льдогенераторов и их применение.</p>
<p>В настоящее время льдогенераторы стали неотъемлемой частью ресторанных, кафе и барных бизнесов. Они также широко используются в домашних условиях, особенно летом, когда необходимо охладить напитки.</p>
<br/>
<div className="flex-2-col"><div><p>Льдогенераторы для дома</p>
<br/>
<p>Льдогенераторы для домашнего использования обычно имеют небольшой размер и предназначены для производства мелкого льда. Они удобны и практичны для использования в бытовых условиях и могут производить от 10 до 50 кг льда в сутки. Некоторые льдогенераторы для дома также могут быть использованы для производства льда различных форм, таких как кубики, гранулы и т.д.</p></div><img
        className="article-img"
        src={require('./IceMachines/pics/home-gen.webp')}
        alt="home-gen"
      />
      <img
        className="article-img-low"
        src={require('./IceMachines/pics/home-gen.webp')}
        alt="home-gen"
      /></div>
<br/>
<div className='flex-2-col'><img
        className="article-img"
        src={require('./IceMachines/pics/comm-gen.webp')}
        alt="comm-gen"
      />
      <div><p>Коммерческие льдогенераторы</p>
<br/>
<p>Коммерческие льдогенераторы обычно имеют большой размер и могут производить большое количество льда в течение короткого времени. Они используются в различных областях, таких как рестораны, кафе, бары, гостиницы и больницы. Они могут производить до 1000 кг льда в сутки и имеют большой запас льда, что делает их идеальным выбором для коммерческих целей.</p></div><img
        className="article-img-low"
        src={require('./IceMachines/pics/comm-gen.webp')}
        alt="comm-gen"
      /></div>
<br/>
<div className='flex-2-col'><div><p>Льдогенераторы с системой очистки</p>
<br/>
<p>Льдогенераторы с системой очистки обеспечивают высокое качество льда и предотвращают возможность развития бактерий и других микроорганизмов. Эти льдогенераторы обычно имеют систему очистки, которая автоматически очищает льдогенератор от любых остатков и микробов.</p></div><img
        className="article-img"
        src={require('./IceMachines/pics/clear-gen.webp')}
        alt="clear-gen"
      />
      <img
        className="article-img-low"
        src={require('./IceMachines/pics/clear-gen.webp')}
        alt="clear-gen"
      /></div>
<br/>
<div className='flex-2-col'><img
        className="article-img"
        src={require('./IceMachines/pics/filt-gen.webp')}
        alt="filt-gen"
      />
      <div><p>Льдогенераторы с системой фильтрации</p>
<br/>
<p>Льдогенераторы с системой фильтрации помогают устранить нежелательные примеси, такие как запахи и вкусы из воды, используемой для производства льда. Это позволяет получать более чистый и свежий лед.</p></div><img
        className="article-img-low"
        src={require('./IceMachines/pics/filt-gen.webp')}
        alt="filt-gen"
      /></div>
<br/>
<span>Льдогенераторы с функцией охлаждения воды</span>
<br/>
<p>Льдогенераторы с функцией охлаждения воды являются удобными для использования в тех случаях, когда требуется производить охлажденную воду и лед одновременно. Они могут быть использованы как для коммерческих, так и для домашних целей.</p>
<br/>
<span>Льдогенераторы с функцией хранения</span>
<br/>
<p>Льдогенераторы с функцией хранения обеспечивают большой запас льда в течение длительного времени. Они удобны в использовании для мероприятий, таких как вечеринки или свадьбы, а также в коммерческих целях. Они могут иметь емкость хранения от 10 до 300 кг льда.</p>
<br/>
<span>Как выбрать льдогенератор</span>
<br/>
<p>При выборе льдогенератора необходимо учитывать несколько факторов, включая объем производства льда, доступность воды и электричества, а также функции, необходимые для конкретного использования.</p>
<br/>
<span>Плюсы использования льдогенератора</span>
<br/>
<p>Льдогенераторы имеют множество преимуществ, включая удобство использования, экономию времени и усиленную гигиену. Они также могут значительно сэкономить расходы на лед в долгосрочной перспективе.</p>
<br/>
<span>Минусы использования льдогенератора</span>
<br/>
<p>Некоторые льдогенераторы могут иметь высокую стоимость, а также требовать определенных условий, таких как доступ к воде и электричеству. Они также могут требовать регулярного обслуживания и очистки.</p>
<br/>
<br/>
</>)

export const LdogenFaq = [
  {
    title: '1. Можно ли использовать льдогенератор для производства льда из других жидкостей?',
    content: 'Нет, льдогенераторы предназначены только для производства льда из воды. Использование других жидкостей может повредить оборудование или привести к производству некачественного льда.',
  },
  {
    title: '2. Какой льдогенер лучше выбрать для домашнего использования?',
    content: 'Льдогенеры для домашнего использования обычно меньше и не такие мощные, как аппараты для профессионального использования. При выборе льдогенера для дома необходимо учитывать свою потребность в продукте и бюджет, который Вы готовы потратить.',
  },
  {
    title: '3. Нужно ли регулярно чистить льдогенератор?',
    content: 'Да, многие льдогенераторы требуют регулярной очистки и обслуживания для обеспечения длительного срока службы и высокого качества продукта.',
  },
  {
    title: '4. Как часто нужно менять фильтр в льдогенераторе',
    content: 'Частота замены фильтра зависит от модели аппарата и качества воды, используемой для производства льда. В руководстве пользователя должна быть указана рекомендуемая частота замены фильтра.',
  },
  {
    title: '5. Какой тип льда следует выбрать?',
    content: 'Выбор типа льда зависит от Ваших нужд. Если Вам нужен лед для охлаждения напитков, то лучше выбрать кубиковый лед, если же Вам нужен лед для декоративных целей, то лучше выбрать в форме гранул.',
  },
]