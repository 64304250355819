import React, { useState } from 'react';

export default function Tabs({ tabs, clas}) {

  
  const [activeTab, setActiveTab] = useState(tabs[0].content);
  
  return (
    <div className='tabs'>
      <ul>
        {tabs.map((tab, index) => (
          <li key={index} onClick={() => setActiveTab(tab.content)}>
            {tab.case}
          </li>
        ))}
      </ul>
      <div className={clas}>
        {activeTab}
      </div>
    </div>
  );
}


