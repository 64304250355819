import React from 'react';
import { useReadItemConfig } from '../../Config/readItemConfig';
import { ShockFreezRepairInner } from './ServicesInner';
import ArticlePageSample from '../../02_samples/ArticlePageSample/ArticlePageSample';

export default function ShockFreezRepair() {
  const path = useReadItemConfig();
  return (
    <ArticlePageSample
      title={'Ремонт камер шоковой заморозки'}
      description={
        'Ремонт камер шоковой заморозки в Нижнем Новгороде | Ремонт камер шоковой заморозки - одна из услуг, которые оказывает компания ООО Нижегородская Холодильная Лига'
      }
      keywords={
        'заморозка, камера, камера шоковой заморозки, ремонт камеры шоковой заморозки'
      }
      content={ShockFreezRepairInner}
      serviceName={'Ремонт камер шоковой заморозки'}
      Do={'отремонтировать камеру шоковой заморозки'}
      path={path}
    />
  );
}
