import React, { useEffect } from "react";
import { preLoaderAnim } from "./PreLoaderAnim";
import logo from '../../00_site_layout/logo.png';

const PreLoader = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);
  return (
    <div className="preloader">
      <div className="texts-container">
        <span>Нижегородская</span>
        <span>Холодильная</span>
        <span>Лига</span>
      </div>
      <img src={logo} alt="logo" />
    </div>
  );
};

export default PreLoader;
