import React from 'react';
import OurPluses from '../OurPluses/OurPluses';
import HowTo from '../HowTo/HowTo';
import BackLinks from '../../../04_ui_kit/BackLinksArticles/BackLinks';

export default function EndOfRead({ serviceName, Do, path }) {
  return (
    <>
      <OurPluses serviceName={serviceName} />
      <HowTo Do={Do} />
      <BackLinks path={path} />
    </>
  );
}
