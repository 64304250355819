import React, { useEffect } from 'react';

export default function Modal({ data }) {

  let Setting = data.content;

  useEffect( () => {document.querySelector('.modal').closest('section').classList.add('locked')},[])
  
  return (
    <div className="modal">
      <div className="modal-title">{data.name}</div>
      <Setting data={data} />
    </div>
  );
}
