import React, {useEffect} from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import Modal from '../Modal/Modal';
import { useState } from 'react';

export default function SettingsItem({ title, items, updateSiteConfig  }) {
  const [modal, setModal] = useState(false);

  function change(e) {
    let funcName = e.target.previousElementSibling.innerText;
    let currentFunc = items.find(el => el.name === funcName).func
    let currentContent = items.find(el => el.name === funcName).content

    setModal({
      name: funcName,
      func: currentFunc,
      content: currentContent,
      back: function update() {
        setModal(false);
        document.querySelector('section').classList.remove('locked')
      },
      updateSiteConfig : updateSiteConfig,
    })
  }

useEffect(() => {
  if (modal === false) {
    document.querySelector('section').classList.remove('locked')
  }
}, [modal])
  

  return (
    <>
      <div className="settings-item">
        <h3 className="setting-title">{title}</h3>

        {items.length
          ? items.map((item) => {
              return (
                <div className="setting-descr" key={item.name}>
                  <div className="setting-name">{item.name}</div>
                  <div id={item.id} className="setting-change button" onClick={change}>
                    Изменить
                  </div>
                </div>
              );
            })
          : null}
        {modal && <Modal data={modal} />}
      </div>
    </>
  );
}
