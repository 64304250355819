import React from 'react'
import { Link } from 'react-router-dom'

export default function CardFace({inner, imgClass, pic, to}) {
  return (
    <>
    <div className="face">
        {pic ? <div className='face-img'>{pic}</div> : null}
        <div className="inner">{inner}</div>
        {to ? <Link to={to} className='button'>Подробнее</Link> : null}
      </div>
    </>
  )
}
