import React from 'react'
import CardFace from '../../../04_ui_kit/CardFace/CardFace'
import { actions, options } from '../../../01_pages/Services/ServicesConst'


export default function HowTo({Do}) {
  return (
    <div className='how-to'>
      <span>Как {Do}</span>
        <br />
        <ol>
          {actions.length ? actions.map(el => {
            return(<li key={el}><CardFace inner={el} /></li>)
          }) : null}
        </ol>
       {options.length ? <><span>
          Для Вашего удобства, мы предлагаем 2 разных варианта сотрудничества:
        </span>
        <ol>
         {options.map(el => {
            return (<li key={el}><CardFace inner={el} /></li>)
          }) }
        </ol></>: null}
    </div>
  )
}
