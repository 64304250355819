import { fonts } from "../../Config/Fonts";
import FontSize from './Settings/FontSize';
import FontWeight from './Settings/FontWeight';
// import SideBarPos from './Settings/SideBarPos';
let root = document.querySelector(':root');

export let settingsConst = [
  {
    title: 'Шрифт',
    items: [
      {
        name: 'Размер шрифта',
        func: function changeSize(size) {
          root.style.setProperty('--mainFont', `${size}px`);
        },
        content:FontSize,
      },
      {
        name: 'Начертание',
        func: function changeWeight(weight) {
          let weightChange
          let font = document.getElementById('font')
          fonts.forEach(el => {if(el.weight === weight){weightChange = el.href}})
          font.setAttribute('href', weightChange)
        },
        content: FontWeight,
      },
    ],
  },
  // {
  //   title: 'Меню навигации',
  //   descr: [
  //     {
  //       name: 'Позиция',
  //       tooltip: 'По умолчанию - Справа',
  //     },
  //   ],
  // },
];
