import React from 'react'
import HelmetSample from '../../02_samples/HelmetSample'
import ModuleSample from '../../02_samples/ModuleSample/ModuleSample'
import {LegalNoticeContent} from './LegalNoticeContent'

export default function LegalNoticePage() {
  return (
    <>
    <HelmetSample
    title={` Правовая информация ООО "Нижегородская Холодильная Лига"`}
    description={
      'Правовая информация ООО Нижегородская Холодильная Лига'
    }
    keywords={
      'холодильное оборудование, холодильное оборудование для магазина, холодильное оборудование для кафе и ресторанов, холодильное оборудование Нижний Новгород, установка холодильного оборудования, ремонт холодильного оборудования, Нижегородская Холодильная Лига'
    }/>
    <ModuleSample titletype='h1' title='Правовая информация ООО "Нижегородская Холодильная Лига' inner={LegalNoticeContent}/>
    </>
  )
}
