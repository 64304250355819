import React from 'react';
import { useReadItemConfig } from '../../../Config/readItemConfig';
import { WhatIsChillerContent } from '../ArticlesInner';
import ArticlePageSample from '../../../02_samples/ArticlePageSample/ArticlePageSample';

export default function WhatIsChiller() {
  const path = useReadItemConfig();
  return (
    <ArticlePageSample
      title={'Что такое чиллер: особенности и классификация'}
      description={
        'Выбор чиллера в Нижнем Новгороде | Что такое чиллер: особенности и классификация'
      }
      keywords={'чиллер, хладагент, холодильное оборудование, конденсатор'}
      content={WhatIsChillerContent}
      path={path}
    />
  );
}
