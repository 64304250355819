import React from 'react';
import ModuleSample from '../../02_samples/ModuleSample/ModuleSample';
import List from '../../03_components/List/List';
import SettingsItem from '../../04_ui_kit/SettingsItem/SettingsItem';
import { settingsConst} from './SettingsConst';

export default function SettingsPage({ updateSiteConfig }) {

  const settings = () => <List clas='settings-list' items={settingsConst} updateConfig={updateSiteConfig} Component={SettingsItem}/>

  return (
    <>
      <ModuleSample
        title={'Настройки'}
        titletype={'h1'}
        className={'settings'}
        Component={settings}
      />
    </>
  );
}
