import React from 'react';
import HelmetSample from '../../02_samples/HelmetSample';
import ModuleSample from '../../02_samples/ModuleSample/ModuleSample';
import Tabs from '../../03_components/Tabs/Tabs';
import {AboutUsContent} from './AboutUsContent';

export default function AboutUsPage() {

  let AboutTabs = () => <Tabs clas={'about-content'} tabs={AboutUsContent}/>
  
  return (
    <>
      <HelmetSample
        title={'О нас'}
        description={`Информация о компании "ООО Нижегородская Холодильная Лига" | Контакты "ООО Нижегородская Холодильная Лига"`}
        keywords={'Нижегородская Холодильная Лига, нижхол, нхл'}
      />


      <ModuleSample title={'О нас'} titletype={'h1'} className={'about-us'} Component={AboutTabs}/>
    </>
  );
}
