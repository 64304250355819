import { useEffect } from 'react';
import HelmetSample from '../../02_samples/HelmetSample';
import ModuleSample from '../../02_samples/ModuleSample/ModuleSample';

import { Hero, Install, Pluses, Production, Services } from './HomeContent';
import { cards } from './HomeContent';
import { questions } from './HomeContent';
import FeedbackForm from '../../03_components/FeedbackForm/FeedbackForm';

export default function HomePage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <HelmetSample
        title={`Холодильное оборудование | Купить промышленное холодильное оборудование для магазина | Производство, установка, монтаж, профессиональный ремонт и обслуживание холодильного оборудования`}
        description={
          'Качественное холодильное оборудование по лучшим ценам в России. Телефон для связи: 283-15-44. Доставка. Гарантии.Установка и обслуживание оборудования любой сложности.'
        }
        keywords={
          'холодильное оборудование, холодильное оборудование для магазина, холодильное оборудование для кафе и ресторанов, холодильное оборудование Нижний Новгород, установка холодильного оборудования, ремонт холодильного оборудования, Нижегородская Холодильная Лига'
        }
      />

      <ModuleSample className={'hero'} inner={Hero} />

      <ModuleSample className={'company-plus'} inner={Pluses} />

      <ModuleSample className={'production'} title={'Шаг 1. Проектировка и производство по ТЗ'} inner={Production} />
      
      <ModuleSample className={'production install'} title={'Шаг 2. Установка'} inner={Install} />
      
      <ModuleSample className={'production repair'} title={'Шаг 3. Обслуживание и ремонт'} inner={Services} />

      {/* <ModuleSample
        Component={cards}
        title={'Наши возможности'}
        className={'home-cards'}
      /> */}

      <ModuleSample
        Component={questions}
        title={'Часто задаваемые вопросы'}
        className={'home-faq'}
      />

      {/* <ModuleSample
        Component={FeedbackForm}
        title={'Обратная связь'}
        className={'feedback'}
      /> */}
    </>
  );
}
