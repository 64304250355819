import React from 'react';
import { useReadItemConfig } from '../../Config/readItemConfig';
import { RefEqRepairInner } from './ServicesInner';
import ArticlePageSample from '../../02_samples/ArticlePageSample/ArticlePageSample';

export default function RefEqRepair() {
  const path = useReadItemConfig();

  return (
    <ArticlePageSample
      title={'Ремонт холодильного оборудования'}
      description={
        'Ремонт холодильного оборудования в Нижнем Новгороде | Ремонт холодильного оборудования - одна из услуг, которые оказывает компания ООО Нижегородская Холодильная Лига'
      }
      keywords={'холодильное оборудование, ремонт холодильного оборудования'}
      content={RefEqRepairInner}
      serviceName={'Ремонт холодильного оборудования'}
      Do={'отремонтировать холодильное оборудование'}
      path={path}
    />
  );
}
