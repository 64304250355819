import { Outlet } from 'react-router-dom';
import { useReadListConfig } from '../../Config/readListConfig';
import HelmetSample from '../../02_samples/HelmetSample';
import ModuleSample from '../../02_samples/ModuleSample/ModuleSample';
import { servicesList } from './ServicesList';
import Tabs from '../../03_components/Tabs/Tabs'

export default function ServicesPage() {
  const config = useReadListConfig();
  const tabs = () => <Tabs tabs={servicesList}/>

  return (
    <>
      <HelmetSample
        title={'Наши услуги'}
        description={
          'Список услуг нашей компании | Ремонт холодильного оборудования'
        }
        keywords={
          'холодильное оборудование, услуги,  ремонт холодильного оборудования'
        }
      />

      <Outlet context={config} />



      <ModuleSample
        Component={tabs}
        title={'Наши возможности'}
        className={'services-list'}
        titletype={'h1'}
      />
    </>
  );
}
