import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';

import gear from '../HomePage/pics/gear.png';
import wrench from '../HomePage/pics/wrench.png';
import List from '../../03_components/List/List';
import Card from '../../04_ui_kit/Card/Card';
import AccordionItem from '../../04_ui_kit/AccordionItem/AccordionItem';

export const cards = () => (
  <List clas="cards-list" items={HomeCards} Component={Card} />
);

export const questions = () => (
  <List clas="accordion" items={HomeFaq} Component={AccordionItem} />
);



export const Hero = (
  <div className="text">
    <h1>
      Промышленное холодильное, климатическое и технологическое оборудование в
      России
    </h1>
    <h5>Прямые поставки от производителя</h5>
    <h5>Монтаж оборудования любой сложности</h5>
    <h5>Ремонт и техническое обслуживание</h5>
    <div className="flex-2-col">
      {/* <Scroll
        to="requestForm"
        smooth={true}
        offset={-(document.documentElement.clientHeight / 3)}
        duration={1000}
        className="button request"
        href="#request"
      >
        Оставить заявку
      </Scroll> */}
      <Link
        to="/services"
        className="button"
        style={{ color: 'white' }}
        // href="#more"
      >
        Подробнее
      </Link>
    </div>
  </div>
);

export const Pluses = (
  <div className="text">
    <h2>Не знаете где купить или заказать холодильное оборудование?</h2>
    <h3>
      Нижегородская Холодильная Лига - это то решение, которое Вам точно
      подойдет! Мы предлагаем:
    </h3>
    <h5>
      Высокое качество продукции - долговечность, надежность, эффективность
    </h5>
    <h5>Широкий ассортимент - оборудование под любые потребности</h5>
    <h5>Гарантии на все виды товаров или услуг</h5>
    <h5>Многолетний опыт наших сотрудников</h5>
    <h5>
      Комплексный сервис - полный цикл обслуживания клиентов от заявки на
      производство до ввода в эксплуатацию
    </h5>
    <h3>Результат за 3 простых шага! </h3>
  </div>
);

const SolProdList = () => (<List  clas="accordion options" items={SolProd} Component={AccordionItem} />)

export const Production = (
 <div className="text center">
  <h2>ТЗ - Производство - Поставка</h2>
  {/* <h3>Виды производимого оборудования</h3> */}
  <SolProdList/>
  <h3>На основе Ваших потребностей поможем составить техническое задание, произведем расчет мощности, подберем соответствующие комплектующие, расчитаем систему управления.</h3>
   <h3>
     Сотрудничая с нами, Вы гарантированно получите то, что нужно!
   </h3>
  </div>
);

const SolProd = [
  {
    title: 'Пример того, что может входить в ТЗ',
    content: (<ul>
      <li>Цель приобретения</li>
    <li>Адрес объекта</li>
    <li>Сроки поставки</li>
    <li>Основные требования</li>
    <li>Климатические условия и режим работы</li>
    <li>Требования по надежности</li>
    <li>Требования безопасности</li>
    <li>Требования к комплектующим</li>
    <li>Требования к эксплуатации</li>
    <li>Производственные характеристики</li>
    <li>и т.д.</li>
    </ul>),
  },
  {
    title: 'Виды производимого оборудования',
    content: (<ul>
      <li>Чиллеры</li>
      <li>Гидромодули</li>
      <li>Ледовые арены</li>
      <li>Холодильные централи </li>
      <li>Гидравлические модули</li>
      <li>Компрессорные станции</li>
      <li>Линии шоковой заморозки </li>
      <li>Водоохлаждающие агрегаты</li>
      <li>Воздухообменное оборудование</li>
      <li>Компрессорно-конденсатные блоки</li>
      <li>Установки подготовки ледяной воды</li>
      <li>Станции центрального холодоснабжения</li>
      <li>Каскадные холодильные машины и установки</li>
      <li>Многокомпрессорные и компрессорно-конденсаторные агрегаты</li>
    </ul>),    
  },]



const SolInstList = () => (<List  clas="accordion options" items={SolInst} Component={AccordionItem} />)

  export const Install = (
    <>
      <div className="text center">
        <h2>Монтаж - не проблема!</h2>
       <SolInstList/>
       <h3>Наши специалисты проведут установку холодильного оборудования любой сложности!</h3>
      </div>
    </>
  );

const SolInst= [
  {
    title: 'Виды устанавливаемого оборудования',
    content: (<ul>
      <li>Льдогенераторы</li>
      <li>Морозильные лари</li>
      <li>Холодильные горки</li>
      <li>Холодильные столы</li>
      <li>Холодильные шкафы</li>
      <li>Холодильные камеры</li>
      <li>Морозильные бонеты</li>
      <li>Камеры шоковой заморозки</li>
      <li>Скороморозильные аппараты</li>
      <li>Холодильные прилавки и витрины</li>
      <li>Складское холодильное оборудование</li>
      <li>Конденсаторы воздушного охлаждения </li>
      <li>Производимое нами оборудование оборудование</li>
      <li>и многое другое</li>
      
    </ul>),
  },
  {
    title: 'Процесс установки',
    content: (<>  Монтаж и пуско-наладочные работы производятся в несколько этапов:
      <br />
      <br />
      1. Подготовительный этап
      <br />
      Выезд специалистов на объект, осмотр установочной площадки
      <br />
      <br />
      2. Этап согласования
      <br />
      Согласование сроков проведения работ
      <br />
      <br />
      3. Установочный этап
      <br />
      <br />
      4. Этап диагностики
      <br />
      Тестирование оборудования на корректную работу
      <br />
      <br />
      5. Пуско-наладочный этап
      <br />
      Финальная настройка оборудования для последующего ввода в эксплуатацию</>),
  },
]


const SolServtList = () => (<List  clas="accordion options" items={SolServ} Component={AccordionItem} />)

export const Services = (
  <>
    <div className="text center">
      <h2>Скажите "Нет" поломкам!</h2>
      <SolServtList/>
      <h3>Заключите договор на обслуживание и наши сотрудники  предотвратят 99% 
      неисправностей</h3>
    </div>
  </>
);

export const ServicesMemo = [
  {
    title: 'Важно!',
    content: (
      <ol>
        <li>
          В том случае, если в работе Вашего оборудования были замечены
          какие-либо изменения или неполадки – необходимо безотлагательно
          обратиться в сервис.
        </li>
        <li>
          Чем дольше холодильное оборудование работает в предаварийном
          состоянии, тем больше будет стоить его ремонт
        </li>
        <li>
          Ремонт холодильного оборудования должен осуществляться
          профессионалами. Недостаток знаний у сервисного работника может
          вылиться в дорогостоящий ремонт
        </li>
        <li>
          Техническое обслуживание в целях профилактики – гарантия стабильной
          работы Вашего оборудования
        </li>
        <li>
          Самой распространенной причиной поломки холодильного оборудования
          является ненадлежащая эксплуатация
        </li>
      </ol>
    ),
  },
];

const SolServ = [...ServicesMemo, 
  {
    title: 'Услуги по ремонту и ТО',
    content: (<ul>
      <li>Диагностика</li>
      <li>Профилактическое обслуживание</li>
      <li>Устранение неполадок</li>
      <br />
      <br />
      <li>а также</li>
      <li>Гарантийное и постгарантийное обслуживание</li>
    </ul>),
  }]

export const HomeCards = [
  {
    imgClass: 'production-img',
    pic: [
      <img key="gear1" className="gear1" src={gear} alt="pic" />,
      <img key="gear2" className="gear2" src={gear} alt="pic" />,
      <img key="gear3" className="gear3" src={gear} alt="pic" />,
    ],
    title: 'Производство',
    descr: (
      <div>
        Мы предлагаем услуги по производству холодильного, климатического и
        технологического оборудования на основании потребностей наших
        покупателей.
        <br />
        <br />
        В нашем ассортименте вы найдете морозильные камеры, холодильные витрины,
        шкафы для хранения продуктов и многое другое. Наши эксперты помогут вам
        выбрать наиболее подходящее оборудование для Вас, учитывая его размеры и
        особенности.
        <br />
        <br />
        Мы предлагаем также различные виды холодильного оборудования для кафе и
        ресторанов. Наше оборудование обеспечивает оптимальные условия хранения
        для различных видов продуктов. Мы также предлагаем специальные решения
        для крупных ресторанов, которые нуждаются в большом холодильном
        пространстве.
        <br />
        <br />
        Наши специалисты спроектируют и произведут оборудование по Вашему
        техническому заданию. В случае отсутствия четкого ТЗ – помогут его
        составить.
        <br />
        <br />
        Также, сотрудничая с нами вы получаете бесценный опыт более 100
        предприятий в Российской Федерации
        <br />
        <br />
      </div>
    ),
    id: 'cardDataServices1',
  },
  {
    imgClass: 'install-img',
    pic: <img key="wrench" className="wrench" src={wrench} alt="pic" />,
    title: 'Установка',
    descr: (
      <div>
        Мы также предлагаем услуги по установке холодильного оборудования.
        <br />
        <br />
        Наши опытные специалисты могут установить оборудование любой сложности и
        обеспечат его бесперебойную работу.
        <br />
        <br />
        Монтаж и пуско-наладочные работы производятся в несколько этапов:
        <br />
        <br />
        1. Подготовительный этап
        <br />
        Выезд специалистов на объект, осмотр установочной площадки
        <br />
        <br />
        2. Этап согласования
        <br />
        Согласование сроков проведения работ
        <br />
        <br />
        3. Установочный этап
        <br />
        <br />
        4. Этап диагностики
        <br />
        Тестирование оборудования на корректную работу
        <br />
        <br />
        5. Пуско-наладочный этап
        <br />
        Финальная настройка оборудования для последующего ввода в эксплуатацию
      </div>
    ),
    id: 'cardDataServices2',
  },
  {
    imgClass: 'service',
    pic: [
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1706.667"
        viewBox="0 0 1280 1181"
        key="svg1"
        id="svg1"
      >
        <path d="M619.3 95.3C606 135.5 578.2 220 557.5 283c-20.7 63-42.5 129.1-48.4 147l-10.8 32.5-234.7.3c-151 .1-234.6.6-234.4 1.2.2.5 84.4 61.3 187.3 135.1 102.9 73.7 188 135.1 189.3 136.3l2.2 2.2-17.6 53.4c-9.6 29.4-29 88.1-42.9 130.5-13.9 42.3-36.6 111.4-50.5 153.5-13.8 42.1-27.1 82.5-29.5 89.7-2.4 7.3-4 13.3-3.6 13.3.5 0 85.8-61 189.7-135.6C588.4 945.6 643 907 644.5 907.2c1.1.3 86.7 61.3 190.3 135.7 103.6 74.4 188.6 135.1 188.8 134.8.5-.5-15.7-50-106.1-325.2-36.4-110.8-37.7-114.5-37.3-115.6.2-.5 85.1-61.8 188.7-136.2 103.6-74.3 188.6-135.8 188.9-136.5.3-.9-47.7-1.2-233.6-1.2-186 0-234.1-.3-234.9-1.3-.5-.6-33.2-99.5-72.7-219.7-39.5-120.2-72.1-218.8-72.5-219.2-.4-.4-11.5 32.2-24.8 72.5z" />
      </svg>,
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1706.667"
        viewBox="0 0 1280 1181"
        key="svg2"
        id="svg2"
      >
        <path d="M619.3 95.3C606 135.5 578.2 220 557.5 283c-20.7 63-42.5 129.1-48.4 147l-10.8 32.5-234.7.3c-151 .1-234.6.6-234.4 1.2.2.5 84.4 61.3 187.3 135.1 102.9 73.7 188 135.1 189.3 136.3l2.2 2.2-17.6 53.4c-9.6 29.4-29 88.1-42.9 130.5-13.9 42.3-36.6 111.4-50.5 153.5-13.8 42.1-27.1 82.5-29.5 89.7-2.4 7.3-4 13.3-3.6 13.3.5 0 85.8-61 189.7-135.6C588.4 945.6 643 907 644.5 907.2c1.1.3 86.7 61.3 190.3 135.7 103.6 74.4 188.6 135.1 188.8 134.8.5-.5-15.7-50-106.1-325.2-36.4-110.8-37.7-114.5-37.3-115.6.2-.5 85.1-61.8 188.7-136.2 103.6-74.3 188.6-135.8 188.9-136.5.3-.9-47.7-1.2-233.6-1.2-186 0-234.1-.3-234.9-1.3-.5-.6-33.2-99.5-72.7-219.7-39.5-120.2-72.1-218.8-72.5-219.2-.4-.4-11.5 32.2-24.8 72.5z" />
      </svg>,
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1706.667"
        viewBox="0 0 1280 1181"
        key="svg3"
        id="svg3"
      >
        <path d="M619.3 95.3C606 135.5 578.2 220 557.5 283c-20.7 63-42.5 129.1-48.4 147l-10.8 32.5-234.7.3c-151 .1-234.6.6-234.4 1.2.2.5 84.4 61.3 187.3 135.1 102.9 73.7 188 135.1 189.3 136.3l2.2 2.2-17.6 53.4c-9.6 29.4-29 88.1-42.9 130.5-13.9 42.3-36.6 111.4-50.5 153.5-13.8 42.1-27.1 82.5-29.5 89.7-2.4 7.3-4 13.3-3.6 13.3.5 0 85.8-61 189.7-135.6C588.4 945.6 643 907 644.5 907.2c1.1.3 86.7 61.3 190.3 135.7 103.6 74.4 188.6 135.1 188.8 134.8.5-.5-15.7-50-106.1-325.2-36.4-110.8-37.7-114.5-37.3-115.6.2-.5 85.1-61.8 188.7-136.2 103.6-74.3 188.6-135.8 188.9-136.5.3-.9-47.7-1.2-233.6-1.2-186 0-234.1-.3-234.9-1.3-.5-.6-33.2-99.5-72.7-219.7-39.5-120.2-72.1-218.8-72.5-219.2-.4-.4-11.5 32.2-24.8 72.5z" />
      </svg>,
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1706.667"
        viewBox="0 0 1280 1181"
        key="svg4"
        id="svg4"
      >
        <path d="M619.3 95.3C606 135.5 578.2 220 557.5 283c-20.7 63-42.5 129.1-48.4 147l-10.8 32.5-234.7.3c-151 .1-234.6.6-234.4 1.2.2.5 84.4 61.3 187.3 135.1 102.9 73.7 188 135.1 189.3 136.3l2.2 2.2-17.6 53.4c-9.6 29.4-29 88.1-42.9 130.5-13.9 42.3-36.6 111.4-50.5 153.5-13.8 42.1-27.1 82.5-29.5 89.7-2.4 7.3-4 13.3-3.6 13.3.5 0 85.8-61 189.7-135.6C588.4 945.6 643 907 644.5 907.2c1.1.3 86.7 61.3 190.3 135.7 103.6 74.4 188.6 135.1 188.8 134.8.5-.5-15.7-50-106.1-325.2-36.4-110.8-37.7-114.5-37.3-115.6.2-.5 85.1-61.8 188.7-136.2 103.6-74.3 188.6-135.8 188.9-136.5.3-.9-47.7-1.2-233.6-1.2-186 0-234.1-.3-234.9-1.3-.5-.6-33.2-99.5-72.7-219.7-39.5-120.2-72.1-218.8-72.5-219.2-.4-.4-11.5 32.2-24.8 72.5z" />
      </svg>,
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1706.667"
        viewBox="0 0 1280 1181"
        id="svg5"
        key="svg5"
      >
        <path d="M619.3 95.3C606 135.5 578.2 220 557.5 283c-20.7 63-42.5 129.1-48.4 147l-10.8 32.5-234.7.3c-151 .1-234.6.6-234.4 1.2.2.5 84.4 61.3 187.3 135.1 102.9 73.7 188 135.1 189.3 136.3l2.2 2.2-17.6 53.4c-9.6 29.4-29 88.1-42.9 130.5-13.9 42.3-36.6 111.4-50.5 153.5-13.8 42.1-27.1 82.5-29.5 89.7-2.4 7.3-4 13.3-3.6 13.3.5 0 85.8-61 189.7-135.6C588.4 945.6 643 907 644.5 907.2c1.1.3 86.7 61.3 190.3 135.7 103.6 74.4 188.6 135.1 188.8 134.8.5-.5-15.7-50-106.1-325.2-36.4-110.8-37.7-114.5-37.3-115.6.2-.5 85.1-61.8 188.7-136.2 103.6-74.3 188.6-135.8 188.9-136.5.3-.9-47.7-1.2-233.6-1.2-186 0-234.1-.3-234.9-1.3-.5-.6-33.2-99.5-72.7-219.7-39.5-120.2-72.1-218.8-72.5-219.2-.4-.4-11.5 32.2-24.8 72.5z" />
      </svg>,
    ],
    title: 'Обслуживание',
    descr: (
      <div>
        Мы предлагаем услуги по плановому техническому обслуживанию, которые
        включают в себя профилактику, диагностику и устранение возможных
        неполадок. Наши квалифицированные специалисты проводят регулярную
        проверку технического состояния оборудования, настраивают системы и
        контролируют работу холодильного оборудования.
        <br />
        <br />
        Наша компания предлагает гарантийное и постгарантийное обслуживание
        холодильного оборудования. Мы заботимся о наших клиентах и предлагаем
        качественный сервис по доступным ценам. Обращайтесь к нам и наши
        специалисты с радостью помогут Вам решить любые проблемы с холодильным
        оборудованием.
        <br />
        <br />
        Помимо этого, мы предлагаем услуги по ремонту холодильного оборудования.
        Наши специалисты имеют многолетний опыт работы с различными моделями
        холодильного оборудования и могут быстро и качественно устранить любые
        неисправности.
      </div>
    ),

    id: 'cardDataServices3',
  },
];

export const HomeFaq = [
  
  {
    title: '1. Сколько стоят услуги?',
    content: (
      <>
        Производство/установка/обслуживание Вашего оборудования рассчитывается
        исходя из множества параметров, поэтому окончательную стоимость Вы
        можете узнать связавшись с нами.
        <br />
        <br />
        Наши специалисты проведут полный расчет и сделают коммерческое предложение.
      </>
    ),
  },
  {
    title:
      '2. Сколько времени займет производство/установка моего оборудования?',
    content: (
      <>
        Время работы зависит от сложности заказа. 
        <br />
        <br />
        Окончательные сроки
        утверждаются после формирования конечного заказа и осмотра установочной
        площадки нашим специалистом.
      </>
    ),
  },
  {
    title: '3. Гарантии',
    content: (
      <>
        На произведенное оборудование и компроненты предоставляется гарантия от
        производителя.
        <br />
        <br />
        На установку оборудования — 1 год.
      </>
    ),
  },
  {
    title: '4. Доставка',
    content: (
      <>
        Мы осуществляем доставку по всей территории России.
        <br />
        <br />
        Наша компания гарантирует своим клиентам, что доставка оборудования
        будет осуществлена в срок, согласованный заранее.
      </>
    ),
  },
  {
    title: '5. Я не нашел своего вопроса',
    content: (
      <>
        В таком случае Вы можете связаться с нами по указанным контактам.
        <br />
        <br />
        Наши специалисты с радостью дадут Вам исчерпывающую информацию по Вашему вопросу.
        <br />
        <br />
        Также мы советуем Вам посетить раздел со{' '}
        <Link to="/articles">статьями о холодильном оборудовании</Link>. В нем
        собрано много интересной и полезной информации.
      </>
    ),
  },
];

