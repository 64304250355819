import React from 'react'
import { Link } from 'react-router-dom'

export default function BackLinks({path}) {

  let currentPath = window.location.pathname

  return (
    <div className="back-links">
          {path === currentPath ? <Link className='button' to={path}>В начало</Link> : <Link className='button' to={path}>Назад</Link>}
          <Link className='button' to="/">Вернуться на главную</Link>
        </div>
  )
}
