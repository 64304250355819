import howChoose from '../Articles/HowToChoose/pics/items.webp';
import chiller from '../Articles/WhatIsChiller/pics/chiller.webp';
import shock from '../Articles/HowToChoose/pics/kamer-shok.webp';
import List from '../../03_components/List/List';
import CardFace from '../../04_ui_kit/CardFace/CardFace';




export const repairList = [
  {
    to: 'remont-holodilnogo-oborudovaniya',
    inner: 'Ремонт холодильного оборудования',
    pic: <img key="howChoose" src={howChoose} alt='equipment'/>,
  },
  {
    to: 'remont-chillera',
    inner: 'Ремонт чиллера',
    pic: <img key="howChoose" src={chiller} alt='chiller'/>,
  },
  {
    to: 'remont-kamer-shokovoy-zamorozki',
    inner: 'Ремонт камер шоковой заморозки',
    pic: <img key="howChoose" src={shock} alt='kamerShock'/>,
  },
  
];

export const servicesList = [
  {
    case: 'Ремонт и ТО',
    content: <List clas="table" items={repairList} Component={CardFace} />,
  },
  {
    case: 'Установка',
    content: <><p style={{padding: 20}}>Раздел в разработке</p></>,
  },
  {
    case: 'Производство',
    content: <><p style={{padding: 20}}>Раздел в разработке</p></>,
  },
]