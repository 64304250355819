import { useEffect } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';

export function useReadItemConfig() {
  useLocation();
  const  path  = useOutletContext();

  useEffect(() => {
   window.scrollTo(0, 0);
  });

  return path
}
