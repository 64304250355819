// font, weight, pos

import { useEffect, useState } from 'react';
import { fonts } from './Fonts';

export default function useSiteConfig() {
  //инициализация констант
  const root = document.querySelector(':root');
  const fontSize = getComputedStyle(root).getPropertyValue('--mainFont');
  let font = document.getElementById('font');
  let fontAttribute = document.getElementById('font').getAttribute('href');
  let currentFont;
  fonts.forEach((el) => {
    if (el.href === fontAttribute) {
      currentFont = el.href;
    }
  });

  //подготовка к работе со стором
  const [siteConfig, setSiteConfig] = useState({
    font: fontSize,
    weight: currentFont,
  });
  const LOCAL_STORAGE_KEY = 'nizhhol';

  //подключение свойств из стора
  root.style.setProperty('--mainFont', siteConfig.font);
  font.setAttribute('href', siteConfig.weight);

  //забираем из стора
  useEffect(() => {
    let storedSettings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    if (storedSettings) {
      setSiteConfig(storedSettings);
    }
  }, []);

  //кладем в стор
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(siteConfig));
  }, [siteConfig]);

  //функция обновления настроек в сторе
  function updateSiteConfig(key, value) {
    setSiteConfig({ ...siteConfig, [key]: value });
  }

  return updateSiteConfig;
}
