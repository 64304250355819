import React from 'react';
import EndOfRead from '../../03_components/ArticleComponents/EndOfRead/EndOfRead';
import List from '../../03_components/List/List';
import AccordionItem from '../../04_ui_kit/AccordionItem/AccordionItem';
import BackLinks from '../../04_ui_kit/BackLinksArticles/BackLinks';

export default function Article({
  title,
  content,
  data,
  serviceName,
  faq,
  Do,
  path,
}) {
  return (
    <article className="article-inner">
      <h1>{title}</h1>
      {serviceName ? (
        <List items={data} clas="accordion" Component={AccordionItem} />
      ) : null}
      {content}
      {faq ? (
        <>
          <h2>Вопросы</h2>
          <List items={faq} clas="accordion" Component={AccordionItem} />
        </>
      ) : null}
      <br />
      <br />
      {serviceName ? (
        <EndOfRead serviceName={serviceName} Do={Do} path={path} />
      ) : (
        <BackLinks path={path} />
      )}
    </article>
  );
}
