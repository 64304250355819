import React from 'react';
import { ServicesMemo } from '../../01_pages/HomePage/HomeContent';
import Article from './Article';
import HelmetSample from '../HelmetSample';

export default function ArticlePageSample({
  title,
  description,
  keywords,
  content,
  data = ServicesMemo,
  serviceName,
  faq,
  Do,
  path,
}) {
  return (
    <>
      <HelmetSample
        title={title}
        description={description}
        keywords={keywords}
      />

      <Article
        title={title}
        content={content}
        data={data}
        serviceName={serviceName}
        faq={faq}
        Do={Do}
        path={path}
      />
    </>
  );
}
