import React from 'react';
import { useReadItemConfig } from '../../../Config/readItemConfig';
import { LdogenContent, LdogenFaq } from '../ArticlesInner';
import ArticlePageSample from '../../../02_samples/ArticlePageSample/ArticlePageSample';

export default function IceMachines() {
  const path = useReadItemConfig();
  return (
    <ArticlePageSample
      title={'Льдогенераторы: какие бывают и для чего нужны'}
      description={
        'Статья расскажет о разных типах льдогенераторов и их преимуществах, а также поможет выбрать подходящий льдогенератор для дома или коммерческого использования.'
      }
      keywords={
        'льдогенератор, производство льда, свежий лед, очистка льдогенератора, выбор льдогенератора, коммерческое использование, фильтр, обслуживание'
      }
      faq={LdogenFaq}
      content={LdogenContent}
      path={path}
    />
  );
}
