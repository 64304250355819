import { Outlet } from 'react-router-dom';
import { useReadListConfig } from '../../Config/readListConfig';
import HelmetSample from '../../02_samples/HelmetSample';
import ModuleSample from '../../02_samples/ModuleSample/ModuleSample';
import { articlesList } from './ArticlesList';
import List from '../../03_components/List/List';
import CardFace from '../../04_ui_kit/CardFace/CardFace';

export default function ArticlesPage() {
  const config = useReadListConfig();
  const articles = () => <List clas='table' items={articlesList} Component={CardFace}/>
  return (
    <>
      <HelmetSample
        title={'Интересные статьи по теме "холодильное оборудование"'}
        description={
          'Сборник статей по самым распростаненным случаям и вопросам из практики'
        }
        keywords={
          'холодильное оборудование, статья,  промышленное холодильное оборудование'
        }
      />

      {/* <LoaderInside/> */}

      <Outlet context={config} />

      <ModuleSample
        Component={articles}
        title={'Интересные статьи'}
        className={'article-list'}
        titletype={'h1'}
      />
    </>
  );
}

//
