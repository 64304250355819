import React from 'react';

export default function List({ clas, items, Component, updateConfig, img }) {
  return (
    <>
      {img ? (
        
        <div className="flex-2-col">
          <img className="img1" src={img} alt="pic" />
          {clas ? (
            <div className={clas}>
              {items.map((item, index) => (
                <Component
                  key={index}
                  {...item}
                  updateSiteConfig={updateConfig}
                />
              ))}
            </div>
          ) : (
            <>
              {items.map((item, index) => (
                <Component
                  key={index}
                  {...item}
                  updateSiteConfig={updateConfig}
                />
              ))}
            </>
          )}
          <img className="img2" src={img} alt="pic" />
        </div>
      ) : (
        <>
          {clas ? (
            <div className={clas}>
              {items.map((item, index) => (
                <Component
                  key={index}
                  {...item}
                  updateSiteConfig={updateConfig}
                />
              ))}
            </div>
          ) : (
            <>
              {items.map((item, index) => (
                <Component
                  key={index}
                  {...item}
                  updateSiteConfig={updateConfig}
                />
              ))}
            </>
          )}
        </>
      )}

      {/* { clas ? <div className={clas}>
      {items.map((item, index) => (
        <Component key={index} {...item} updateSiteConfig={updateConfig}/>
      ))}</div> :  <>{items.map((item, index) => (
        <Component key={index} {...item} updateSiteConfig={updateConfig}/>
      ))}</>}
      {img ? (
          <img className="img" src={img} alt="pic" />
        ) : null} */}
    </>
  );
}
