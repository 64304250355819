export const fonts = [
  {
    weight:'Тонкий',
    href:'https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap'
  },
  {
    weight:'Средний',
    href:'https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap'
  },
  {
    weight:'Толще среднего',
    href:'https://fonts.googleapis.com/css2?family=Roboto&display=swap'
  },
  {
    weight:'Толстый',
    href:'https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap'
  },
]