import React, {useState} from 'react';

export default function FontSize({data}) {

  const [value, setValue] = useState('')
  let root = document.querySelector(':root');
  let fontSize = getComputedStyle(root).getPropertyValue('--mainFont')

  function acceptSetting() {
    if ( value > 40 || value < 15) {
      alert('Диапазон значений от 15 до 40');
      return
   }
    data.func(value)
    data.updateSiteConfig('font',`${value}px`)
  }
  
  function defaultSetting() {
    data.func(25)
    data.updateSiteConfig('font', '25px')
  }
  
    function changeValue(e) {
      if (e.target.value > 40 || e.target.value < 0) {
       alert('Диапазон значений от 15 до 40')
       e.target.value = ''
       return 
      }
      setValue(e.target.value)
    }

  return (
    <>
      <div> Текущее значение: {fontSize}</div>
      <div className="center">
        {' '}
        Изменение значения по умолчанию может привести к некорректному
        отображению содержимого сайта
      </div>
      <input
        className="modal-input"
        type="number"
        placeholder="Введите значение"
        onChange={(e) => changeValue(e)}
      />
      <div className="buttons">
        <div className="accept button" onClick={acceptSetting}>
          Принять
        </div>
        <div className="default button" onClick={defaultSetting}>
          По умолчанию
        </div>
        <div className="cancel button" onClick={data.back}>
          Назад
        </div>
      </div>
    </>
  );
}
