import React from 'react'

export default function OurPluses({serviceName}) {
  return (
    <>
    <p>
          В ООО «Нижегородская Холодильная Лига» Вы можете получить комплекс
          работ по услуге «{serviceName}» от профессионалов.
          Наши сотрудники знают свое дело и относятся к своей работе со всей
          ответственностью.{' '}
        </p>
        <p>Сотрудничая с нами, Вы получаете:</p>
        <ol>
          <li>
            Предотвращение большинства типичных поломок ввиду качественно
            проведенных диагностических и профилактических работ
          </li>
          <li>
            Поддержка техники в работоспособном состоянии на протяжении всего
            сотрудничества
          </li>
          <li>
            В случае аварии – максимально быстрое реагирование и возврат техники
            в рабочее состояние
          </li>
        </ol>
        <p>
          Наши сотрудники оснащены всем необходимым оборудованием, которое
          используется при оказании такой услуги, как «{serviceName}». Все работы производятся по месту нахождения
          сломавшегося агрегата.
        </p>
        <br />
    </>
  )
}
