import React from 'react';
import { useReadItemConfig } from '../../../Config/readItemConfig';
import { HowToChooseContent } from '../ArticlesInner';
import ArticlePageSample from '../../../02_samples/ArticlePageSample/ArticlePageSample';

export default function HowToChoose() {
  const path = useReadItemConfig();

  return (<>
    <ArticlePageSample
      title={
        'Как выбрать холодильное оборудование: классификация, правила выбора'
      }
      description={
        'Выбор холодильного оборудования в Нижнем Новгороде | Как выбрать холодильное оборудование: классификация, правила выбора'
      }
      keywords={
        'холодильное оборудование, температурный режим,  промышленное холодильное оборудование'
      }
      content={HowToChooseContent}
      path={path}
    /></>
  );
}
