import React from 'react';

export const AboutUsContent = [
  {
    case: 'Информация',
    content: (
      <>
        <h2>О компании</h2>
        <p>
          Нижегородская холодильная лига – компания, которая специализируется на
          производстве, установке и ремонте холодильного оборудования для
          бизнеса. Мы создаем высококачественные холодильные системы, отвечающие
          потребностям наших клиентов, с учетом их индивидуальных требований и
          особенностей бизнеса.
        </p>
        <p>
          Наша команда состоит из профессионалов, которые имеют многолетний опыт
          работы в области холодильного оборудования. Мы постоянно
          совершенствуем наши технологии и используем только проверенные и
          качественные материалы, чтобы обеспечить надежность и долговечность
          нашего оборудования.
        </p>
        <p>
          Мы предлагаем широкий спектр услуг, включая индивидуальный проект,
          монтаж, наладку и ремонт холодильного оборудования для различных
          бизнесов – от магазинов и ресторанов до складов и производственных
          предприятий. Наша цель – обеспечить оптимальные условия хранения и
          сохранности продуктов, а также обеспечить бесперебойную работу
          холодильных систем наших клиентов.
        </p>
        <p>
          Мы готовы предложить индивидуальный подход к каждому клиенту и помочь
          им выбрать наиболее эффективное и экономичное решение для их бизнеса.
          Обращаясь к нам, вы можете быть уверены в качестве наших услуг и
          надежности нашего оборудования.
        </p>
      </>
    ),
  },
  {
    case: 'Реквизиты',
    content: <>
    <h2>Реквизиты</h2>
    <div className='flex-2-col'>
    <div className='info'><p>Наименование: Нижегородская Холодильная Лига</p>
    <p>
      Юридический адрес: 603163, Нижегородская область, г Нижний
      Новгород, Казанское ш, д. 7, кв. 273
    </p>
    <p>ИНН: 5260485444</p>
    <p>ОГРН: 1225200029306</p>
    <p>КПП: 526001001</p>
    <p>Руководитель: Непряхина Татьяна Владимировна</p></div>
    <div className='info2'>
    <p>
      <strong>Основной вид деятельности по ОКВЭД</strong>
    </p>
    <p>33.12 Ремонт машин и
      оборудования</p>
    <p><strong>Дополнительные виды деятельности</strong></p>
    <p>
      28.25 Производство промышленного холодильного и вентиляционного
      оборудования
    </p>
    <p>
      46.69.9 Торговля оптовая прочими машинами, приборами, аппаратурой
      и оборудованием общепромышленного и специального назначения
    </p>
    <p>46.90 Торговля оптовая неспециализированная</p>
    <p>
      47.91.2 Торговля розничная, осуществляемая непосредственно при
      помощи информационно-коммуникационной сети Интернет
    </p></div></div></>,
  },
  {
    case: 'Контакты',
    content: <><h2>Контакты</h2>
    {/* <p>Адрес: г. Нижний Новгород, ул. Ленина, д. 1</p> */}
    <div className='contacts'>
      <p>Режим работы: Пн-Пт 8.00 - 20.00</p>
    <p>Телефон: <a className='number' href="tel:2831544"> 283-15-44</a></p>
    <p>По всем вопросам: <a href="mailto:info@nizhhol.ru">info@nizhhol.ru</a></p>
    <p>Технический отдел: <a href="mailto:ssm@nizhhol.ru">ssm@nizhhol.ru</a></p>
    <p>Бухгалтерия: <a href="mailto:buh@nizhhol.ru">buh@nizhhol.ru</a></p>
    <p>По вопросам производства: <a href="mailto:prof@nizhhol.ru">prof@nizhhol.ru</a></p></div></>,
  },
];