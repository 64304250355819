import React from 'react'
import { fonts } from '../../../Config/Fonts';

export default function FontWeight({data}) {
  let weight = document.getElementById('font').getAttribute('href')
  let currentWeight
  fonts.forEach(el => {
    if(el.href === weight) {currentWeight = el.weight}
  })

  function acceptSetting(e) {
    let value
    fonts.forEach(el => {
      if(el.weight === e.target.innerText) {value = el.href}
    })
    data.func(e.target.innerText)
    data.updateSiteConfig('weight', value)
  }

  function defaultSetting() {
    data.updateSiteConfig('weight', 'https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap')
  }
  return (
    <>
    <div> Текущее значение: {currentWeight}</div>
    <div className='buttons'>{fonts.length ? fonts.map(el => {
      return <div className='button accept' key={el.weight} onClick={acceptSetting}>{el.weight}</div>
    }) :null}</div>
    <div className="buttons">
        <div className="default button" onClick={defaultSetting}>
          По умолчанию
        </div>
        <div className="cancel button" onClick={data.back}>
          Назад
        </div>
      </div>
    
    </>
  )
}
