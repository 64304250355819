import { useEffect } from 'react';

export function useReadListConfig(selector) {

  useEffect(() => {
    window.scroll(0, 0);
  },[]);

  const path = window.location.pathname

  return path;
}
