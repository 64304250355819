import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import menuTriggerImg from './menu-trigger.svg';
import logo from '../logo.png';

let menu;
let menuTrigger;
let buttons;
let img;

export default function Sidebar() {
  const body = document.body;

  function menuClose(e) {
    if (
      e.target !== menu &&
      e.target !== img &&
      menu.classList.contains('menu-active')
    ) {
      menu.classList.toggle('menu-active');
      menuTrigger.classList.toggle('active-trigger');
      menu.style.right = '';
      return;
    }
  }

  function menuToggle() {
    if (!menu.classList.contains('menu-active')) {
      menu.classList.toggle('menu-active');
      menuTrigger.classList.toggle('active-trigger');
      menuTrigger.classList.add('jump-done');
      menu.style.right = '0';
      return;
    }
    menu.classList.toggle('menu-active');
    menuTrigger.classList.toggle('active-trigger');
    menu.style.right = '';
  }

  function changeUrl(e) {
    if (buttons.includes(e.target)) {
      menu.classList.toggle('menu-active');
      menuTrigger.classList.toggle('active-trigger');
      menu.style.right = '';

      return;
    }
  }

  useEffect(() => {
    menu = document.getElementById('slide-menu');
    menuTrigger = menu.querySelector('.menu-trigger');
    buttons = Array.from(menu.querySelectorAll('.button'));
    img = menuTrigger.querySelector('img');
    body.onclick = menuClose;
  }, [body]);

  return (
    <>
      <nav id="slide-menu" className="menu-right" onClick={changeUrl}>
        <h2 className="center">Меню навигации</h2>
        <div className="content">
          <ul>
            <li>
              <Link to="/" className="button">
                Главная
              </Link>
            </li>
            <li>
              <Link to="/services" className="button">
                Услуги
              </Link>
            </li>
            <li>
              <Link to="/articles" className="button">
                Статьи
              </Link>
            </li>
            <li className="line-before"></li>
            <li>
              <Link to="/settings" className="button">
                Параметры
              </Link>
            </li>
            <li><Link to='/about' className='button'>О нас</Link></li>
          </ul>
          <img src={logo} alt="Logo" />
        </div>
        {/* <div className='center'>Ведутся технические работы. <br/>Некоторые элементы сайта могут отображаться некорректно.</div> */}
        <div className="menu-trigger">
          <img src={menuTriggerImg} alt="menu-trigger" onClick={menuToggle} />
        </div>
        <a className="phone" href="tel:2831544">
          {' '}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M256,32c123.5,0,224,100.5,224,224S379.5,480,256,480S32,379.5,32,256S132.5,32,256,32 M256,0C114.625,0,0,114.625,0,256
		s114.625,256,256,256s256-114.625,256-256S397.375,0,256,0L256,0z M398.719,341.594l-1.438-4.375
		c-3.375-10.062-14.5-20.562-24.75-23.375L334.688,303.5c-10.25-2.781-24.875,0.969-32.405,8.5l-13.688,13.688
		c-49.75-13.469-88.781-52.5-102.219-102.25l13.688-13.688c7.5-7.5,11.25-22.125,8.469-32.406L198.219,139.5
		c-2.781-10.25-13.344-21.375-23.406-24.75l-4.313-1.438c-10.094-3.375-24.5,0.031-32,7.563l-20.5,20.5
		c-3.656,3.625-6,14.031-6,14.063c-0.688,65.063,24.813,127.719,70.813,173.75c45.875,45.875,108.313,71.345,173.156,70.781
		c0.344,0,11.063-2.281,14.719-5.938l20.5-20.5C398.688,366.062,402.062,351.656,398.719,341.594z"
            />
          </svg>
        </a>
      </nav>
    </>
  );
}
