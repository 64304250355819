import React from "react";

export default function AccordionItem ({ title, content }) {
let itemHeight

const growTiming = {
  duration: 300,
  iterations: 1,
}
  function showContent(e) {
    let item = e.target.closest('.accordion-item')
    let sign = e.target.closest('.accordion-title').querySelector('.sign')
    let content = item.querySelector('.accordion-content')

    

    if (item.classList.contains('active')) {
      let itemCurrentHeight = item.offsetHeight
      content.classList.add('disappear')

      setTimeout(() => {
        item.classList.remove('active')
      sign.innerText = '+'
      const growDown = [
        { height: `${itemCurrentHeight}px` },
        {height: `${itemHeight}px`}
      ];
      item.animate(growDown, growTiming)
      setTimeout(() => {
        content.style.display = 'none'
        content.classList.remove('disappear')
        content.style.visibility = ''
      }, 100);
      }, 600);
      
      
      return
    }

  itemHeight = item.offsetHeight

    item.classList.add('active')
    sign.innerText = '-'
    content.style.display = 'block'
    
    const contentHeight = content.offsetHeight
    const growUp = [
      { height: `${itemHeight}px` },
      {height: `${itemHeight + contentHeight}px`}
    ];
    item.animate(growUp, growTiming)

    setTimeout(() => {
      content.classList.add('appear')
      content.style.visibility = 'visible'
    }, 100);
    setTimeout(() => {
      content.classList.remove('appear')
    }, 800);
  }

  return (
     <div className="accordion-item" >
      <div className="accordion-title" onClick={showContent}>
        <div>{title}</div>
        <div className='sign'>+</div>
      </div>
        <div className="accordion-content">{content}
        </div>
    </div>
  );
};
