import React from 'react';

export default function ModuleSample({
  Component,
  title,
  titletype,
  className,
  b_img,
  inner,
}) {
  function ModuleSampleTitle({ title, titletype }) {
    if (title) {
      return titletype === 'h1' ? (
        <h1 className="title">{title}</h1>
      ) : (
        <h2 className="title">{title}</h2>
      );
    }
  }
  return (
    <>
      <section className={`module ${className}`}>
        {b_img ? (
          <img className="background-img" src={b_img} alt="pic" />
        ) : null}

        <ModuleSampleTitle title={title} titletype={titletype}/>

        {Component ? <Component /> : null}
        {inner ? inner : null}
      </section>
    </>
  );
}
