import React from 'react';
import { useReadItemConfig } from '../../Config/readItemConfig';
import { ShockFreezingFaq, ShockFreezingContent } from './ArticlesInner';
import ArticlePageSample from '../../02_samples/ArticlePageSample/ArticlePageSample';

export default function ShockFreezing() {
  const path = useReadItemConfig();
  return (
    <ArticlePageSample
      title={'Шоковая заморозка: технология и оборудование'}
      description={
        'Выбор оборудования шоковой заморозки в Нижнем Новгороде | Шоковая заморозка: технология и оборудование'
      }
      keywords={
        'заморозка, шоковая, шоковая заморозка. оборудование, камера, продукция, продукт, технология'
      }
      faq={ShockFreezingFaq}
      content={ShockFreezingContent}
      path={path}
    />
  );
}
