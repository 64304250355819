import React from 'react';
import { useReadItemConfig } from '../../Config/readItemConfig';
import { ChillRepairInner } from './ServicesInner';
import ArticlePageSample from '../../02_samples/ArticlePageSample/ArticlePageSample';

export default function ChillRepair() {
  const path = useReadItemConfig();

  return (
    <ArticlePageSample
      title={'Ремонт чиллера'}
      description={
        'Ремонт чиллера в Нижнем Новгороде | Ремонт чиллера - одна из услуг, которые оказывает компания ООО Нижегородская Холодильная Лига'
      }
      keywords={'ремонт чиллера, чиллер, оборудование'}
      content={ChillRepairInner}
      serviceName={'Ремонт чиллера'}
      Do={'отремонтировать чиллер'}
      path={path}
    />
  );
}
