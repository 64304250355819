import React from 'react';
import { Helmet } from 'react-helmet';

export default function HelmetSample({ title, description, keywords, uniqMeta }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description}></meta>
      <meta name="keywords" content={keywords}></meta>

      <meta property="og:title" content={title}></meta>
      <meta property="og:description" content={description}></meta>

      <meta name="apple-mobile-web-app-title" content={title}></meta>

      {uniqMeta}
    </Helmet>
  );
}
