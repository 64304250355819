import { Link } from 'react-router-dom';

export const RefEqRepairInner = (
  <>
    {' '}
    <p>
      Как бы не было печально, но у каждого технического средства имеется свой
      срок службы. Так и у промышленного холодильного оборудования он есть.
      Однако, чтобы уменьшить финансовые вложения и продлить срок службы
      имеющихся агрегатов, необходимо в установленный для машин срок проводить
      их полное техническое обслуживание. Своевременная профилактика и ремонт –
      это основа бесперебойной работы как любого механизма, так и любого
      предприятия!
    </p>
    <p>
      Услуга «Ремонт холодильного оборудования» является актуальной для любого
      предприятия, у которого в эксплуатации находятся{' '}
      <Link className="hyper" to="/articles/kak-vibrat-holodilnoe-oborudovanie">
        холодильные агрегаты любого вида
      </Link>
      , а именно:
    </p>
    <ol>
      <li>
        Складские помещения. Сохранность продукции – залог финансового успеха
      </li>
      <li>
        Фармацевтика. Лекарства напрямую влияют на жизни и здоровье людей,
        поэтому налицо безусловная необходимость поддержания холодильного
        оборудования в рабочем состоянии
      </li>
      <li>
        Флористика. Цветы должны быть свежими, когда за ними приходит
        покупатель.
      </li>
      <li>
        Общепит. Ненадлежащее хранение продуктов питания может привести к
        огромным проблемам.
      </li>
    </ol>
  </>
);

export const ChillRepairInner = (
  <>
    <p>
      На сегодняшний день существует огромное количество чиллеров, которые
      различаются между собой характеристиками и особенностями. Как правило, все
      они являются высокотехнологическими системами, состоящими из множества
      узлов и деталей.
    </p>
    <p>
      Нарушение условий использования, отсутствие профилактических работ по
      обслуживанию, ненадлежащая эксплуатация – это лишь малая часть тех причин,
      которые могут привести к выходу из строя Вашего оборудования.
    </p>
    <p>
      Услуга «Ремонт чиллера» является актуальной для любого предприятия, у
      которого в эксплуатации находятся{' '}
      <Link className="hyper" to="/articles/chto-takoe-chiller">
        чиллеры любого вида
      </Link>
    </p>
    <p>Мы производим такие работы, как:</p>
    <ol>
      <li>Диагностика</li>
      <li>Устранение утечек и заправка хладагента</li>
      <li>Ремонт систем контроля и управления</li>
      <li>Ремонт и замена комплектующих</li>
      <li>Восстановление герметичности</li>
    </ol>
  </>
);

export const ShockFreezRepairInner = <>
<p>На сегодняшний день, технология шоковой заморозки довольно сильно распространена и является достаточно популярной. Именно поэтому, на рынке можно найти огромное количество предложений от разных производителей, каждое из которых обладает своей определенной спецификой.</p>
<p>Так как камеры шоковой заморозки, как правило, эксплуатируются на постоянной основе, то очевидно, что они довольно сильно подвержены риску аварий. Учитывая важность таких холодильных машин для бизнеса, ремонт должен быть произведен только специалистом с необходимой квалификацией.</p>
<p>Услуга «Ремонт камер шоковой заморозки» является актуальной для любого предприятия, у которого в эксплуатации находятся <Link className="hyper" to="/articles/shokovaya-zamorozka">камеры шоковой заморозки любого вида</Link></p>
<p>Мы находим причины поломки и устраняем их. Поводом для ремонтных работ является:</p>
<ol>
  <li>Непрерывная работа аппарата</li>
  <li>Наличие потеков масла</li>
  <li>Недостаточная мощность аппарата</li>
  <li>Вода внутри камеры</li>
  <li>Гул электродвигателя</li>
  <li>Посторонние шумы и вибрации</li>
  <li>Не работает компрессор</li>
  <li>Срабатывает защитное реле</li>
  <li>Не работают кнопки, таймеры, датчики</li>
  <li>Отсутствие герметичности и многое другое</li>
</ol>
</>;
