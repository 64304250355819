import howChoose from '../Articles/HowToChoose/pics/items.webp'
import chiller from '../Articles/WhatIsChiller/pics/chiller.webp'
import shock from '../Articles/HowToChoose/pics/kamer-shok.webp';
import conditioner from '../Articles/Conditioner/pics/conditioner.webp'
import ldoger from '../Articles/HowToChoose/pics/ldogen.webp'

export const articlesList = [
  {
    to: 'kak-vibrat-holodilnoe-oborudovanie',
    inner:
      'Как выбрать холодильное оборудование: классификация, правила выбора',
      pic: <img key='howChoose' src={howChoose} alt='equipment'/>,
  },
  {
    to:'chto-takoe-chiller',
    inner:'Что такое чиллер: особенности и классификация',
    pic: <img key='howChoose' src={chiller} alt='chiller'/>,
  },
  {
    to: 'shokovaya-zamorozka',
    inner:
      'Шоковая заморозка: технология и оборудование',
      pic: <img key="howChoose" src={shock} alt='kamerShock'/>,
  },
  {
    to: 'conditioners',
    inner:'Кондиционеры: особенности и виды',
    pic: <img key="howChoose" src={conditioner} alt='conditioner'/>,
  },
  {
    to: 'ldogeneratori',
    inner:'Льдогенераторы: какие бывают и для чего нужны',
    pic: <img key="howChoose" src={ldoger} alt='ldogen'/>,
  },
];